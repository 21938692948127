import '../../App.css';

function Team() {
    return (
        <div>
            <section className="py-12 bg-white">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">

                    <div className='max-w-6xl mx-auto '>
                        <h1 className="text-6xl font-bold sm:text-start items-center pt-5 pb-10">
                            Our <span className='text-core-900'>Team</span>
                        </h1>

                        <div className="grid grid-cols-1 gap-4 px-8 mt-12 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3 sm:px-0">
                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-1.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Albert Flores
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                CEO & Founder
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-2.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Ralph Edwards
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                CTO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-3.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Theresa Webb
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                CMO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-4.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Jane Cooper
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Art Director
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-5.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Guy Hawkins
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Senior Graphic Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-6.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Annette Black
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                UI Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-7.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Jacob Jones
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                3D Artist
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-8.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Eleanor Pena
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Junior Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-2.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Alex Rix
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Junior Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-4.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Jane Cooper
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Art Director
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-5.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Guy Hawkins
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                Senior Graphic Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overflow-hidden bg-white">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <img className="object-cover w-16 h-16 rounded-full shrink-0" src="https://landingfoliocom.imgix.net/store/collection/niftyui/images/team/7/member-6.png" alt="" />
                                        <div className="flex-1 ml-4">
                                            <p className="text-base font-bold text-gray-900">
                                                Annette Black
                                            </p>
                                            <p className="mt-1 text-sm font-medium text-gray-500">
                                                UI Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Team;

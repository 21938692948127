import axios from 'axios';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { setGlobalState } from '../utils/globalState';

const instance = axios.create({
  baseURL: 'https://api-launchy.rshme.codes/api/v1',
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if(error.response.status === 401){
      try {
          var provider = new WalletConnectProvider({
              rpc: {
                  1: "https://cloudflare-eth.com/",
                  137: "https://polygon-rpc.com/",
              },
          });
          await provider.disconnect()
      } catch (error) {
          console.log(error);
      }
      setGlobalState("walletAddress", null);
      setGlobalState("dataProfile", []);
      localStorage.removeItem('LSwalletLogin');
    }
    return Promise.reject(error);
  }
);

export default instance;

import '../../App.css';

function WWD() {
    return (
        <div>
            <section className="py-20 bg-white">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">

                    <div>
                        <ul className="max-w-6xl mx-auto space-y-12">
                            <li className="lg:grid lg:grid-cols-12 sm:items-start">
                                <div className='col-span-5 centered-flex h-full'>
                                    <div className=''>
                                        <p className="text-6xl font-bold sm:text-start items-center">
                                            About <span className='text-core-900'>Us</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-7 mt-3 sm:mt-0 sm:ml-10">
                                    <p className="mt-5 text-base font-medium text-gray-500">
                                        📍 Launchy we’re on a mission to build the most fun way to create, share, and collect nfts. We’re growing a new web3 social network from the ground up, built on top of crypto wallets and shared NFT ownership.
                                        <br />
                                        <br />
                                        Whether you want to mint the next Mona Lisa 🎨, tickets to your concert 🎟️, unique membership cards 🗂️, or just a picture of your cat 🐈 — we’re excited to power your creativity
                                    </p>
                                </div>
                            </li>

                            <li className="lg:grid lg:grid-cols-12 sm:items-start">
                                <div className='col-span-5 centered-flex h-full'>
                                    <div className=''>
                                        <p className="text-6xl font-bold sm:text-center items-center">
                                            What <span className='text-core-900'>We Do</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-7 mt-3 sm:mt-0 sm:ml-10">
                                    <p className="mt-5 text-base font-medium text-gray-500">
                                        Whether you want to mint the next Mona Lisa 🎨, tickets to your concert 🎟️, unique membership cards 🗂️, or just a picture of your cat 🐈 — we’re excited to power your creativity.
                                        What does Primitives unlock?
                                        <br />
                                        <br />
                                        📥 fast + free nft minting (all done sustainably on solana)<br />
                                        💬 effortless token-gated chatrooms to connect with friends<br />
                                        🤌 on-chain profiles + digital identity<br />
                                        💸 seamless connections for ETH + SOL-based wallets<br />
                                        😌 accessible to everyone (not just web3 experts!) To celebrate our launch on Smoothie, we’re gifting an exclusive early-user beta nft to thank you for your support and provide behind-the-scenes access as we build Primitives 💜

                                    </p>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default WWD;

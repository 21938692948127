import '../../App.css';

function Story() {
    return (
        <div>
            <section className="py-12 bg-gray-100">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">

                    <div className='max-w-6xl mx-auto '>
                        <h1 className="text-6xl font-bold sm:text-start items-center pt-5 pb-10">
                            Our <span className='text-core-900'>Stories</span>
                        </h1>
                        <div className="lg:grid lg:grid-cols-12">
                            <div className='col-span-2 md:flex lg:block'>
                                <div className='text-center mt-4 mb-4 lg:mb-28 md:w-full'>
                                    <h1 className='font-bold text-6xl'>2022</h1>
                                    <span>Founded</span>
                                </div>
                                <div className='text-center mt-4 mb-4 lg:mb-28 md:w-full'>
                                    <h1 className='font-bold text-6xl'>78</h1>
                                    <span>Employee</span>
                                </div>
                                <div className='text-center mt-4 mb-4 lg:mb-28 md:w-full'>
                                    <h1 className='font-bold text-6xl'>741</h1>
                                    <span>Companies</span>
                                </div>
                            </div>
                            <p className="col-span-10 lg:border-l-2 lg:border-gray-300 lg:pl-12 lg:ml-4 mt-5 text-base font-medium text-gray-500">
                                💢Launchy first launched in 2022, but our story started a couple of years earlier when Guillaume Salles and Steven Fabre met and started working closely together on real‑time creative tools🎨.
                                <br/>
                                <br/>
                                👬 They first connected when they were both working on the design tools team at InVision: Guillaume as an engineer👨‍💻 and Steven as a product designer 🖼️. They bonded at an in-person team event in San Francisco🌆 over being the only two French🇫🥖 people in the company, and also their shared passion for making creative tools⛏️.
                                <br/>
                                <br/>
                                They eventually decided to leave their jobs to focus their efforts full-time on building a Google Slides competitor 📌. The first several months were difficult. Guillaume was working from Montreal and Steven was working from New York🌃, and they struggled to find a solution that would enable them to build a tool with the multiplayer functionality that was so critical⚡️. The tools they did manage to find had myriad problems. They didn’t integrate easily into their infrastructure, made it difficult to build a version history panel and multiplayer undo/redo📕, were optimized for text collaboration rather than layer-based creative tools and required them to cobble multiple services together in order to have something work end-to-end🤔.
                                <br/>
                                <br/>
                                Eventually they decided to create their own🙌. Along the way, they realized that if they were having this problem, other companies must be as well 📈. And so they decided to drop the presentation tool, and focus on turning the powerful backend they’d built into a set of APIs that any team could use to build real‑time collaborative products🎉.
                                <br/>
                                <br/>
                                <span className='text-core-900'>And that’s how Launchy was born 🥳.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Story;

import '../../App.css';
import { FaTwitter, FaLink, FaLinkedin, FaEllipsisH } from 'react-icons/fa';
import {
    useParams
} from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from '../../utils/axiosInt';
import * as moment from 'moment';
import Subscribe from '../Subscribe';
import { setGlobalState, useGlobalState } from '../../utils/globalState';

import userIcon from '../../assets/icon/user.png';

function StoriesDetail() {
    let { slug: slugParam } = useParams();
    const [dataStories, setDataStories] = useState({});
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [idComment, setIdComment] = useState('');
    const [commentNew, setCommentNew] = useState('');
    const [comment, setComment] = useState('');
    const [commentReply, setCommentReply] = useState('');
    const [isReply, setIsReply] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditReply, setIsEditReply] = useState(false);
    const [replyIndex, setReplyIndex] = useState(0);
    const [editIndex, setEditIndex] = useState(0);
    const [editIndexReply, setEditIndexReply] = useState(0);
    const [tooltipStatus, setTooltipStatus] = useState(0);
    const [dataUser] = useGlobalState("dataProfile");
    const [walletLogin] = useGlobalState("walletAddress");

    const getDataStoriesSlug = async () => {
        const response = await axios.get(`/story/${slugParam}`);
        setDataStories(response.data.data);
    }

    const deleteComment = async (id, slug) => {
        await axios.delete(`/story/${slug}/comment/${id}`).catch(function (error) {
            console.log(error);
        });
        getDataStoriesSlug(slug);
        setTooltipStatus(0);
    }

    const editComment = async (id, slug) => {
        let data = {
            comment: commentNew
        }

        await axios.put(`/story/${slug}/comment/${id}`, data).catch(function (error) {
            console.log(error);
        });
        getDataStoriesSlug(slug);
        setTooltipStatus(0);
    }

    const commentProduct = async (slug) => {

        let data = {
            "comment": comment
        }

        try {
            await axios.post(`/story/${slug}/comment`, data)
            getDataStoriesSlug(slug);
            setComment('');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    const commentProductReply = async (commentId, productSlug) => {
        console.log(commentId, productSlug);
        let data = {
            "comment": commentReply
        }

        try {
            await axios.put(`/story/${productSlug}/comment/${commentId}/reply`, data)
            getDataStoriesSlug(productSlug);
            setIsReply(false);
            setCommentReply('');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    useEffect(() => {
        getDataStoriesSlug();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <section className="pb-12 pt-5 bg-white">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="mx-auto 2xl:max-w-7xl">

                        <div className="mt-5 lg:grid lg:grid-cols-12 lg:gap-x-4 xl:gap-x-4 reverse-box">
                            <Subscribe />

                            <div
                                className="lg:mt-12 lg:pl-4 lg:pr-40 md:px-4 lg:mt-0 lg:col-span-9 w-full">
                                <div className="mt-2 w-full mb-6 items-center justify-between">
                                    <div className='mt-2 flex items-center justify-between'>
                                        <div class="flex items-center">
                                            <div class="ml-3">
                                                <span class="text-base font-normal text-gray-500 m-none"> {moment(dataStories?.published_at).fromNow()} </span>
                                            </div>
                                        </div>
                                        <div className='flex space-x-2'>
                                            <a href='https://twitter.com/launchy_' target="_blank" rel="noreferrer">
                                                <FaTwitter size="20" color='#9096AB' />
                                            </a>
                                            <a href='https://www.linkedin.com/company/launch-y/' target="_blank" rel="noreferrer">
                                                <FaLinkedin size="20" color='#9096AB' />
                                            </a>
                                            <button data-bs-toggle="tooltip" title="Copy" onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                await navigator.clipboard.writeText(window.location.href);
                                                setIsToast(true);
                                                setToastMessage('Link Copied!');
                                            }}>
                                                <FaLink size="20" color='#9096AB' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <span className="block text-2xl py-2 lg:py-0 font-bold md:font-semibold text-gray-900 sm:text-2xl xl:text-4xl font-pj mb-2">{dataStories?.title}</span>
                                <span className="block text-lg py-2 lg:py-0 font-bold md:font-semibold text-gray-400 sm:text-lg xl:text-xl font-pj">{dataStories?.subtitle}</span>
                                {dataStories?.banner !== null ?
                                    <img className="w-full rounded-lg py-6" style={{ margin: '0', width: '50vh' }} src={'https://api-launchy.rshme.codes/storage/' + dataStories?.banner} alt="" />
                                    :
                                    <img className="w-full rounded-lg py-6" style={{ margin: '0' }} src="https://via.placeholder.com/1920x1080.png?text=Not+Found" alt="" />
                                }
                                <p>
                                    <td style={{ display: 'block', width: '100%' }} dangerouslySetInnerHTML={{ __html: dataStories?.content }} />
                                </p>

                                <div className='mt-10'>
                                    <div className='flex items-center'>
                                        {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                            <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                            :
                                            <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                        }
                                        <div className="relative w-full px-2">
                                            <input value={comment} onChange={(e) => { setComment(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                        </div>
                                        {walletLogin !== null ?
                                            <button onClick={() => {
                                                commentProduct(dataStories?.slug);
                                            }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                Comment
                                            </button>
                                            :
                                            <button onClick={() => {
                                                setIsToast(true);
                                                setToastMessage('Connect your wallet first.');
                                            }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                Comment
                                            </button>
                                        }
                                    </div>
                                </div>

                                {/* Comment */}
                                {dataStories?.comments?.map((itemComment, i) => {
                                    return (
                                        <div className='mt-8' key={i}>
                                            <div className='flex items-center'>
                                                {itemComment?.user?.photo !== null ?
                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + itemComment?.user?.photo} alt="" />
                                                    :
                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                }
                                                <span className='font-medium ml-3'>{itemComment?.user.name}</span>
                                            </div>
                                            <div className='border-l-2 border-gray-300 px-4 mx-2 mt-2'>
                                                {/* {commentStatus ?
                                                    <div>
                                                        <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                                        <button className='text-md' onClick={() => {
                                                            setCommentStatus(false)
                                                            setCommentNew('')
                                                        }}>cancel</button>
                                                    </div>
                                                    : */}
                                                {i !== editIndex ?
                                                    <span className='font-base'>
                                                        {itemComment?.comment}
                                                    </span>
                                                    :
                                                    <>
                                                        {!isEdit ?
                                                            <span className='font-base'>
                                                                {itemComment?.comment}
                                                            </span>
                                                            :
                                                            <div className='flex items-center'>
                                                                <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                    editComment(itemComment?._id, dataStories?.slug);
                                                                    setIsEdit(false);
                                                                }}>Edit</button>
                                                                <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                    setIsEdit(false)
                                                                    setCommentNew('')
                                                                }}>Cancel</button>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {/* } */}
                                                <div className='flex items-center space-x-6 mt-4'>
                                                    {/* <div className='flex justify-end items-center'>
                                                        <span className='text-sm font-bold text-gray-400 mr-2'>Upvote</span>
                                                        <div style={{ width: '2vh' }}>
                                                            <img alt="" src={upvoteIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                        </div>
                                                    </div> */}
                                                    {walletLogin !== null &&
                                                        <button onClick={() => {
                                                            setReplyIndex(i);
                                                            setCommentReply("@" + itemComment?.user?.name + " ");
                                                            setIsReply(true);
                                                        }} className='text-sm font-bold text-gray-400'>Reply</button>
                                                    }
                                                    <span className='text-sm text-gray-400'>{moment(itemComment?.created_at).fromNow()}</span>
                                                    {dataUser?._id === itemComment?.user?._id &&
                                                        <div className="relative md:mt-0" onMouseEnter={() => setTooltipStatus(i + 1)} onMouseLeave={() => setTooltipStatus(0)}>
                                                            <div className="mr-2 cursor-pointer">
                                                                <FaEllipsisH className='text-gray-400' />
                                                            </div>
                                                            {tooltipStatus === (i + 1) && (
                                                                <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                    <div className='flex'>
                                                                        <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                            setEditIndex(i)
                                                                            setIsEdit(true);
                                                                            setTooltipStatus(0)
                                                                            setCommentNew(itemComment?.comment);
                                                                        }}>Edit</button>
                                                                    </div>
                                                                    <hr />
                                                                    <div className='flex'>
                                                                        <button onClick={() => { deleteComment(itemComment?._id, dataStories?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                    </div>
                                                                </div>
                                                            )}{" "}
                                                        </div>
                                                    }
                                                    {itemComment?.created_at !== itemComment?.updated_at &&
                                                        <div className="mr-2 cursor-pointer">
                                                            <div className='text-gray-400 text-xs'>Edited</div>
                                                        </div>
                                                    }
                                                </div>


                                                {i !== replyIndex ? null :
                                                    <>
                                                        {isReply &&
                                                            <>
                                                                <hr className='mt-6' />
                                                                <div key={i} className='flex items-center my-2'>
                                                                    {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                                                        :
                                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                    }
                                                                    <div className="relative w-full px-2">
                                                                        <input value={commentReply} onChange={(e) => { setCommentReply(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                                                    </div>
                                                                    <div className='flex items-center'>
                                                                        <button onClick={() => {
                                                                            commentProductReply(itemComment?._id, dataStories?.slug);
                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                                            Reply
                                                                        </button>
                                                                        <button onClick={() => {
                                                                            setIsReply(false);
                                                                            setCommentReply('');
                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none">
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <hr className='mb-6' />
                                                            </>
                                                        }
                                                    </>
                                                }

                                                {/* Reply Comment */}
                                                {itemComment?.comments?.map((replyComment, index) => {
                                                    return (
                                                        <div className='mt-4'>
                                                            <div className='flex items-center'>
                                                                {replyComment?.user?.photo !== null ?
                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + replyComment?.user?.photo} alt="" />
                                                                    :
                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                }
                                                                <span className='font-medium ml-3'>{replyComment?.user?.name}</span>
                                                            </div>
                                                            <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>

                                                                {index !== editIndexReply ?
                                                                    <span className='font-base'>
                                                                        {replyComment?.comment}
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        {!isEditReply ?
                                                                            <span className='font-base'>
                                                                                {replyComment?.comment}
                                                                            </span>
                                                                            :
                                                                            <>
                                                                                {replyComment?._id === idComment ?
                                                                                    <div className='flex items-center'>
                                                                                        <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                                        <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                                            editComment(replyComment?._id, dataStories?.slug);
                                                                                            setIsEditReply(false);
                                                                                        }}>Edit</button>
                                                                                        <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                                            setIsEditReply(false)
                                                                                            setCommentNew('')
                                                                                        }}>Cancel</button>
                                                                                    </div>
                                                                                    :
                                                                                    <span className='font-base'>
                                                                                        {replyComment?.comment}
                                                                                    </span>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>
                                                                <span className='text-sm text-gray-400'>{moment(replyComment?.created_at).fromNow()}</span>

                                                                {dataUser?._id === replyComment?.user?._id &&
                                                                    <div key={index} className="relative md:mt-0" onMouseEnter={() => { setIdComment(replyComment?._id); setTooltipStatus(index + 1 + 'r'); }} onMouseLeave={() => setTooltipStatus(0)}>
                                                                        <div className="mr-2 cursor-pointer">
                                                                            <FaEllipsisH className='text-gray-400' />
                                                                        </div>
                                                                        {replyComment?._id === idComment &&
                                                                            <>
                                                                                {tooltipStatus === (index + 1 + 'r') && (
                                                                                    <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                                        <div className='flex'>
                                                                                            <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                                                setEditIndexReply(index)
                                                                                                setIsEditReply(true);
                                                                                                setTooltipStatus(0)
                                                                                                setCommentNew(replyComment?.comment);
                                                                                            }}>Edit</button>
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className='flex'>
                                                                                            <button onClick={() => { deleteComment(replyComment?._id, dataStories?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}{" "}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                }
                                                                {replyComment?.created_at !== replyComment?.updated_at &&
                                                                    <div className="mr-2 cursor-pointer">
                                                                        <div className='text-gray-400 text-xs'>Edited</div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                isToast &&
                <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default StoriesDetail;

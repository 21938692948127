import '../../App.css';
import { supabase } from '../../sources/client';
import { useEffect, useState } from 'react';

function RecapInvest() {
    const [dataTable, setDataTable] = useState([]);

    function NewsComponent(data) {
        console.log(data);
        let split = data.data.split('\n').filter(datas => datas !== "");

        return (
            <div>
                {split.map(function (item, i) {
                    return (
                        <div>
                            <a className="text-core-900" href={item} target="_blank" rel="noreferrer">{item}</a>
                        </div>
                    )
                })}
            </div>
        )
    }

    async function fetchData() {
        try {
            const { data } = await supabase.from('angelInvestWeb3').select()
            setDataTable(data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <section className="py-12">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">

                    <div className='max-w-6xl mx-auto '>
                        <div className='pb-6'>
                            <p class="text-lg lg:text-xl font-bold text-gray-900">Top 167 Angel Investors In Web3</p>
                        </div>

                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg scroll-tab">
                            <table class="w-full text-sm text-left  ">
                                <thead class="text-xs text-core-500 uppercase ">
                                    <tr>
                                        <th scope="col" class="px-6 py-3  ">

                                        </th>
                                        <th scope="col" class="px-6 py-3  ">
                                            Investor
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Affiliate
                                        </th>
                                        <th scope="col" class="px-6 py-3  ">
                                            Fund Affiliation
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Investment Sectors
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            List of Investments
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Twitter
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            LinkedIn
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Investment News
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataTable.map(function (item, i) {
                                        return (
                                            <tr class="border-b border-core-900 ">
                                                <td class="px-6 py-4">
                                                    {i + 1}
                                                </td>
                                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap   ">
                                                    {item.investor}
                                                </th>
                                                <td class="px-6 py-4">
                                                    {item.affiliate}
                                                </td>
                                                <td class="px-6 py-4  ">
                                                    {item.fund_affiliation}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {item.investment_sectors}
                                                </td>
                                                <td class="px-6 py-4">
                                                    {item.list_of_investments}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <a href={item.twitter} target="_blank" rel="noreferrer" className="text-core-500">{item.twitter}</a>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <a href={item.linkedin} target="_blank" rel="noreferrer" className="text-core-500">{item.linkedin}</a>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <NewsComponent data={item.investment_news} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default RecapInvest;

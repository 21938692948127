import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
    dataProfile: [],
    walletAddress: null,
    isActiveProfile: false,
    isSubsDefault: false,
    isActiveProduct: false,
    isActiveSlider: false,
    dataSlider: [],
    indexSlider: 0,
});

export { setGlobalState, useGlobalState };
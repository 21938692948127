import '../../App.css';
import { FaTwitter, FaLink, FaLinkedin } from 'react-icons/fa';
import axios from '../../utils/axiosInt';
import { useEffect, useState } from 'react';
import * as moment from 'moment';
import { Link } from "react-router-dom";
import Subscribe from '../Subscribe';

function Stories() {
    const [dataStories, setDataStories] = useState([]);
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [ynftMore, setynftMore] = useState(5);
    // const [dataCategory, setDataCategory] = useState([]);
    const [filterCategory, setFilterCategory] = useState([]);

    const getDataStories = async () => {
        let uri = new URL('https://api-launchy.rshme.codes/api/v1/story?withUnPublish=false');

        if (filterCategory.length !== 0) {
            for (let i = 0; i < filterCategory.length; i++) {
                uri.searchParams.append('tagId', filterCategory[i]);
            }
        };
        uri.searchParams.append('limit', ynftMore);

        const response = await axios.get(uri);
        var foundValue = response.data.data.filter(obj => obj.published_at !== null);
        let sortData = foundValue.sort(function (a, b) {
            return new Date(b.published_at).getTime() - new Date(a.published_at).getTime();
        });
        setDataStories(sortData);
    }

    // const getDataCategory = async () => {
    //     const response = await axios.get(`/category`).catch(function (error) {
    //         console.log(error);
    //     });
    //     setDataCategory(response.data.data);
    // }

    useEffect(() => {
        getDataStories();
    }, [ynftMore]);

    // useEffect(() => {
    //     getDataCategory();
    // }, []);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    return (
        <div>
            <section className="pb-12 pt-5 bg-white mx-auto 2xl:max-w-7xl">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="mx-auto">

                        <div className="mt-5 lg:grid lg:grid-cols-12 lg:gap-x-4 xl:gap-x-4 reverse-box">
                            <Subscribe />

                            <div className="lg:mt-12 px-4 lg:mt-0 lg:prose-lg lg:col-span-9 lg:border-r">
                                {/* <div className='w-full'>
                                    <div className='w-full space-x-2 text-center md:text-start'>
                                        <ul className="flex space-x-2 m-none scroll-category">
                                            {dataCategory.length !== 0 &&
                                                <li className='mt-1 md:mt-0 ml-2 md:ml-0' style={{ padding: '0' }}>
                                                    <input onChange={() => {
                                                        for (let i = 0; i < dataCategory.length; i++) {
                                                            var elm = document.getElementById(i + 1 + 'd');
                                                            if (elm.checked) {
                                                                elm.click();
                                                            }
                                                            setFilterCategory([]);
                                                        }
                                                    }} type="checkbox" id={0 + 'd'} name="filter-ynft" value={null} className="hidden peer" defaultChecked />
                                                    <label for={0 + 'd'} className="inline-flex text-gray-400 items-center justify-between w-full px-2 cursor-pointer peer-checked:border-b peer-checked:border-black peer-checked:text-black hover:text-black hover:border-b hover:border-black">
                                                        All
                                                    </label>
                                                </li>
                                            }
                                            {dataCategory.map(function (item, i) {
                                                return (
                                                    <li className='mt-1 md:mt-0' style={{ padding: '0' }}>
                                                        <input
                                                            onChange={(e) => {
                                                                var elm = document.getElementById('0d');
                                                                if (elm.checked) {
                                                                    elm.click();
                                                                }
                                                                if (e.target.checked) {
                                                                    setFilterCategory([
                                                                        ...filterCategory,
                                                                        item._id
                                                                    ]);
                                                                } else {
                                                                    setFilterCategory(
                                                                        filterCategory.filter((x) => x.indexOf(item._id) === -1),
                                                                    );
                                                                }
                                                            }} type="checkbox" id={i + 1 + 'd'} name="filter-ynft" value={item.name} className="hidden peer" />
                                                        <label for={i + 1 + 'd'} className="inline-flex text-gray-400 items-center justify-between w-full px-2 cursor-pointer peer-checked:border-b peer-checked:border-black peer-checked:text-black hover:text-black hover:border-b hover:border-black">
                                                            {item.name}
                                                        </label>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div> */}

                                <div className='pt-2'>
                                    {dataStories.map(function (item, i) {
                                        return (
                                            <div key={i} className="mx-auto mt-2">
                                                <Link to={`/stories/${item.slug}`} className="grid items-center grid-cols-1 mx-auto md:grid-cols-3 md:gap-x-8 hover:bg-gray-100 hover:rounded-lg p-4">
                                                    <div className="md:order-2">
                                                        <div className="max-w-md mx-auto">
                                                            <div className="aspect-w-5 aspect-h-3">
                                                                {item.banner !== null ?
                                                                    <img className="w-full h-full rounded-lg object-cover" style={{ margin: '0' }} src={'https://api-launchy.rshme.codes/storage/' + item.banner} alt="" />
                                                                    :
                                                                    <img className="w-full h-full rounded-lg object-cover" style={{ margin: '0' }} src="https://via.placeholder.com/1920x1080.png?text=Not+Found" alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="md:px-6 text-center md:text-left md:p-0 md:order-1 col-span-2">
                                                        <span className="block text-xl font-extrabold text-gray-900 sm:text-xl xl:text-2xl font-pj tracking-widest">{item.title}</span>
                                                        <span className="block mt-2 text-lg font-normal text-gray-600 font-pj truncate-summary">{item.subtitle}</span>
                                                        <div className="mt-2 flex items-center justify-between">
                                                            <span className="text-sm text-gray-900">{moment(item?.published_at).fromNow()}</span>
                                                            <div className='flex text-gray-500'>
                                                                <div className='flex space-x-2'>
                                                                    <button onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        window.open('https://twitter.com/launchy_', '_blank');
                                                                    }}>
                                                                        <FaTwitter size="20" color='#9096AB' />
                                                                    </button>
                                                                    <button onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        window.open('https://www.linkedin.com/company/launch-y/', '_blank');
                                                                    }}>
                                                                        <FaLinkedin size="20" color='#9096AB' />
                                                                    </button>
                                                                    <button data-bs-toggle="tooltip" title="Copy" onClick={async (e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        await navigator.clipboard.writeText(window.location.href + '/' + item.slug);
                                                                        setIsToast(true);
                                                                        setToastMessage('Link Copied!');
                                                                    }}>
                                                                        <FaLink size="20" color='#9096AB' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}

                                    <div className='w-full text-end mt-4 lg:mt-2'>
                                        <button onClick={() => {
                                            setynftMore(ynftMore + 5);
                                        }}>
                                            <span className='text-base font-extrabold'>Show more</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                isToast &&
                <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default Stories;

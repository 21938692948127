import '../../App.css';
import { FaDiscord } from 'react-icons/fa';
import ProfileDetail from './ProfileDetail';
import axios from '../../utils/axiosInt';
import { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { useNavigate } from 'react-router-dom';
import { setGlobalState, useGlobalState } from '../../utils/globalState';
import Subscribe from '../Subscribe';

function Profile() {
    let navigate = useNavigate();
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [walletLogin] = useGlobalState("walletAddress");

    const getDataUser = async () => {
        const response = await axios.get(`/user/${walletLogin}`);
        setGlobalState("dataProfile", response.data.data);
    }

    useEffect(() => {
        if (walletLogin !== null) {
            getDataUser();
        } else {
            navigate('/');
        }
    }, [walletLogin]);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    return (
        <div>
            <section className="pb-12 pt-5 bg-white mx-auto 2xl:max-w-7xl">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="mx-auto">

                        <div className="mt-5 lg:grid lg:grid-cols-12 lg:gap-x-4 xl:gap-x-4 reverse-box">
                            <Subscribe/>

                            <div
                                className="lg:px-4 lg:prose-lg lg:col-span-9 w-full lg:border-r">
                                <ProfileDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                isToast &&
                <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default Profile;

import { useEffect } from 'react';
import '../../App.css';

function FAQ() {
    useEffect(() => {
        const items = document.querySelectorAll(".accordion-item button");

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (let i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }

            if (itemToggle === 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }

        items.forEach(item => item.addEventListener('click', toggleAccordion));
    }, []);

    return (
        <div>
            <section className="py-12 bg-gray-100">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">

                    <div className='max-w-6xl mx-auto '>
                        <h1 className="text-6xl font-bold sm:text-start items-center pt-5 pb-10">
                            FAQ
                        </h1>

                        <div className="accordion">
                            <div className="accordion-item">
                                <button id="accordion-button-1" style={{ display: 'flex' }} aria-expanded="false">
                                    <span className="w-full accordion-title">Do you have a launch template or checklist?</span>
                                    <div>
                                        <svg style={{ color: 'rgb(234, 51, 35)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" strokeWidth="2" stroke="rgb(234, 51, 35)" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill="#ea3323"></path>
                                        </svg>
                                    </div>
                                </button>
                                <div className="accordion-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button id="accordion-button-2" style={{ display: 'flex' }} aria-expanded="false">
                                    <span className="w-full accordion-title">How can i submit my site</span>
                                    <div>
                                        <svg style={{ color: 'rgb(234, 51, 35)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" strokeWidth="2" stroke="rgb(234, 51, 35)" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill="#ea3323"></path>
                                        </svg>
                                    </div>
                                </button>
                                <div className="accordion-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button id="accordion-button-3" style={{ display: 'flex' }} aria-expanded="false">
                                    <span className="w-full accordion-title">Can i add more image or video to my submission ?</span>
                                    <div>
                                        <svg style={{ color: 'rgb(234, 51, 35)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" strokeWidth="2" stroke="rgb(234, 51, 35)" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill="#ea3323"></path>
                                        </svg>
                                    </div>
                                </button>
                                <div className="accordion-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button id="accordion-button-4" style={{ display: 'flex' }} aria-expanded="false">
                                    <span className="w-full accordion-title">How long does it take for my site to be approved ?</span>
                                    <div>
                                        <svg style={{ color: 'rgb(234, 51, 35)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" strokeWidth="2" stroke="rgb(234, 51, 35)" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill="#ea3323"></path>
                                        </svg>
                                    </div>
                                </button>
                                <div className="accordion-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FAQ;

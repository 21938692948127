import '../../App.css';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from '../../utils/axiosInt';
import * as moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { setGlobalState, useGlobalState } from '../../utils/globalState';

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

// import required modules
import { Navigation } from "swiper";

// import logo1 from '../../assets/logo/logo1.png';
// import logo2 from '../../assets/logo/logo2.png';

// import upvoteIcon from '../../assets/icon/upvote.png';
import userIcon from '../../assets/icon/user.png';
import commentIcon from '../../assets/icon/comment.png';
import linkWhiteIcon from '../../assets/icon/link-white.png';

import { FaEllipsisH } from 'react-icons/fa';

function Discover() {
    const [urlYoutube, setUrlYoutube] = useState('');
    const [commentNew, setCommentNew] = useState('');
    const [idComment, setIdComment] = useState('');
    const [filterDateFrom, setFilterDateFrom] = useState(null);
    const [filterDateTo, setFilterDateTo] = useState(null);
    const [tooltipStatus, setTooltipStatus] = useState(0);
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [comment, setComment] = useState('');
    const [commentReply, setCommentReply] = useState('');
    const [isReply, setIsReply] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditReply, setIsEditReply] = useState(false);
    const [replyIndex, setReplyIndex] = useState(0);
    const [editIndex, setEditIndex] = useState(0);
    const [editIndexReply, setEditIndexReply] = useState(0);
    const [openDiscover, setOpenDiscover] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [ynftMore, setynftMore] = useState(10);
    const [smlrMore, setsmlrMore] = useState(4);
    const [dataProduct, setDataProduct] = useState([]);
    const [dataUser] = useGlobalState("dataProfile");
    const [dataCategoryDc, setDataCategoryDc] = useState([]);
    const [dataCategoryMobile, setDataCategoryMobile] = useState([]);
    const [dataCategoryStatus, setDataCategoryStatus] = useState(false);
    const [dataProductShow, setDataProductShow] = useState([]);
    const [dataSimilarProduct, setDataSimilarProduct] = useState([]);
    const [similarCategory, setSimilarCategory] = useState([]);
    const [latestSlug, setLatestSlug] = useState();
    const [filterCategory, setFilterCategory] = useState([]);
    const mobileQuery = useMediaQuery("(max-width:767px)");
    const [walletLogin] = useGlobalState("walletAddress");
    const [isPreload, setIsPreload] = useState(false);
    const [isActiveProduct] = useGlobalState("isActiveProduct");

    const handleClickOpen = () => {
        setOpenDiscover(true);
    };

    const handleClose = () => {
        setOpenDiscover(false);
    };

    const getDataCategory = async () => {
        const response = await axios.get(`/category`).catch(function (error) {
            console.log(error);
        });
        setDataCategoryDc(response.data.data);
        if (dataCategoryStatus) {
            setDataCategoryMobile(response.data.data);
        } else {
            setDataCategoryMobile(response.data.data.slice(0, 4));
        }
    }

    const getDataUserSlug = async () => {
        const response = await axios.get(`/user/${walletLogin}`).catch(function (error) {
            console.log(error);
        });
        setGlobalState("dataProfile", response.data.data);
    }

    const getDataSimilarProduct = async (slug, productCategory) => {
        let uri = new URL('https://api-launchy.rshme.codes/api/v1/product?');

        if(productCategory === undefined)return;
        // category array object to array string
        var ctgryData = productCategory.map(function (item) {
            return item['_id'];
        });

        if (ctgryData.length !== 0) {
            for (let i = 0; i < ctgryData.length; i++) {
                uri.searchParams.append('categories', ctgryData[i]);
            }
        };

        uri.searchParams.append('limit', smlrMore);

        const resp = await axios.get(uri).catch(function (error) {
            console.log(error);
        });

        var foundValue = resp.data.data.filter(obj => obj.approved_at !== null);

        var findWithout = foundValue.filter(obj => obj.slug !== slug);

        setDataSimilarProduct(findWithout);
    }

    const getDataProductSlug = async (slug) => {
        const response = await axios.get(`/product/${slug}`).catch(function (error) {
            console.log(error);
        });
        let data = [];
        data.push(response.data.data);
        setLatestSlug(slug);
        setSimilarCategory(response.data.data.categories);
        let resultUrl = response.data.data.youtube_url?.replaceAll("watch?v=", "embed/");
        setUrlYoutube(resultUrl);
        setDataProductShow(data);
    }

    const getDataProduct = async () => {
        let uri = new URL('https://api-launchy.rshme.codes/api/v1/product?');

        if (filterCategory.length !== 0) {
            for (let i = 0; i < filterCategory.length; i++) {
                uri.searchParams.append('categories', filterCategory[i]);
            }
        };

        if (filterDateFrom !== null || filterDateTo !== null) {
            let dateFrom = moment(filterDateFrom).format("YYYY-MM-DD");
            let dateTo = moment(filterDateTo).format("YYYY-MM-DD");
            uri.searchParams.append('startDate', dateFrom);
            uri.searchParams.append('endDate', dateTo);
        }

        uri.searchParams.append('limit', ynftMore);

        const resp = await axios.get(uri).catch(function (error) {
            console.log(error);
        });

        var foundValue = resp.data.data.filter(obj => obj.approved_at !== null);

        setDataProduct(foundValue);
        setGlobalState("isActiveProduct", true);
    }

    const voteProductSlug = async (product) => {
        try {
            await axios.put(`/product/${product}/upvote`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            getDataProductSlug(product);
            getDataProduct();
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    const voteProduct = async (product) => {
        try {
            await axios.put(`/product/${product}/upvote`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            getDataProduct();
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    const deleteComment = async (id, slug) => {
        await axios.delete(`/product/${slug}/comment/${id}`).catch(function (error) {
            console.log(error);
        });
        getDataProduct();
        getDataProductSlug(slug);
        setTooltipStatus(0);
    }

    const commentProduct = async (product) => {

        let data = {
            "comment": comment
        }

        try {
            await axios.post(`/product/${product}/comment`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            getDataProductSlug(product);
            getDataProduct();
            setComment('');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    const editComment = async (id, slug) => {
        let data = {
            comment: commentNew
        }

        await axios.put(`/product/${slug}/comment/${id}`, data).catch(function (error) {
            console.log(error);
        });
        getDataProductSlug(slug);
        getDataProduct();
        setTooltipStatus(0);
    }

    const commentProductReply = async (productId, productSlug) => {

        let data = {
            "comment": commentReply
        }

        try {
            await axios.put(`/product/${productSlug}/comment/${productId}/reply`, data)
            getDataProductSlug(productSlug);
            getDataProduct();
            setIsReply(false);
            setCommentReply('');
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        getDataProduct();
    }, [filterCategory]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataCategory();
    }, [dataCategoryStatus]);

    useEffect(() => {
        getDataProduct();
    }, [ynftMore]);

    useEffect(() => {
        getDataSimilarProduct(latestSlug, similarCategory);
    }, [smlrMore, similarCategory, latestSlug]);

    if (isActiveProduct)
        return (
            <>
                <div className='mt-10'>
                    <span className="text-2xl md:text-5xl font-black text-gray-900 sm:text-5xl my-4 lg:my-0">
                        Disc<span className='text-core-900'>over</span>
                    </span>
                    <div className='md:flex self-center my-3 lg:my-0'>
                        <form>
                            <ul className="flex w-full space-x-2 m-none scroll-category">
                                <li className='mt-1 lg:mt-0 ml-2 md:ml-0' style={{ padding: '0' }}>
                                    <input onChange={() => {
                                        for (let i = 0; i < dataCategoryDc.length; i++) {
                                            var elm = document.getElementById(i + 1 + 'dsc');
                                            if (elm.checked) {
                                                elm.click();
                                            }
                                            setFilterCategory([]);
                                        }
                                    }} type="checkbox" id={0 + 'dsc'} name="filter-ynft" value={null} className="hidden peer" defaultChecked />
                                    <label for={0 + 'dsc'} className="text-sm p-2 inline-flex items-center justify-between w-full bg-gray-100 border border-gray-100 rounded-lg cursor-pointer peer-checked:bg-black peer-checked:text-white hover:text-gray-600 hover:bg-gray-100">
                                        All
                                    </label>
                                </li>
                                {!mobileQuery ?
                                    <>
                                        {dataCategoryDc.map(function (item, i) {
                                            return (
                                                <li className='mt-1 md:mt-0' style={{ padding: '0' }}>
                                                    <input
                                                        onChange={(e) => {
                                                            var elm = document.getElementById('0dsc');
                                                            if (elm.checked) {
                                                                elm.click();
                                                            }
                                                            if (e.target.checked) {
                                                                setFilterCategory([
                                                                    ...filterCategory,
                                                                    item._id
                                                                ]);
                                                            } else {
                                                                setFilterCategory(
                                                                    filterCategory.filter((x) => x.indexOf(item._id) === -1),
                                                                );
                                                            }
                                                        }} type="checkbox" id={i + 1 + 'dsc'} name="filter-ynft" value={item.name} className="hidden peer" />
                                                    <label for={i + 1 + 'dsc'} className="text-sm p-2 inline-flex items-center justify-between w-full bg-gray-100 border border-gray-100 rounded-lg cursor-pointer peer-checked:bg-black peer-checked:text-white hover:text-gray-600 hover:bg-gray-100">
                                                        {item.name}
                                                    </label>
                                                </li>
                                            )
                                        }
                                        )}
                                    </>
                                    :
                                    <>
                                        {dataCategoryMobile.map(function (item, i) {
                                            return (
                                                <li className='mt-1 md:mt-0' style={{ padding: '0' }}>
                                                    <input
                                                        onChange={(e) => {
                                                            var elm = document.getElementById('0dsc');
                                                            if (elm.checked) {
                                                                elm.click();
                                                            }
                                                            if (e.target.checked) {
                                                                setFilterCategory([
                                                                    ...filterCategory,
                                                                    item._id
                                                                ]);
                                                            } else {
                                                                setFilterCategory(
                                                                    filterCategory.filter((x) => x.indexOf(item._id) === -1),
                                                                );
                                                            }
                                                        }} type="checkbox" id={i + 1 + 'dsc'} name="filter-ynft" value={item.name} className="hidden peer" />
                                                    <label for={i + 1 + 'dsc'} className="text-sm p-2 inline-flex items-center justify-between w-full bg-gray-100 border border-gray-100 rounded-lg cursor-pointer peer-checked:bg-black peer-checked:text-white hover:text-gray-600 hover:bg-gray-100">
                                                        {item.name}
                                                    </label>
                                                </li>
                                            )
                                        }
                                        )}
                                        {!dataCategoryStatus &&
                                            <li className='mt-1 md:mt-0 ml-2 md:ml-0' style={{ padding: '0' }}>
                                                <button onClick={() => { setDataCategoryStatus(true); getDataCategory(); }} className="text-sm p-2 inline-flex items-center justify-between w-full bg-gray-100 border border-gray-100 rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100">
                                                    More
                                                </button>
                                            </li>
                                        }
                                    </>
                                }
                            </ul>
                        </form>
                        <div className='w-full self-center mr-2 md:mr-0'>
                            <button onClick={() => { setOpenFilter(true) }} className='flex px-2 pt-4 md:pt-0 md:px-0 md:ml-auto'>
                                <span className='font-bold'>Filter </span>
                                <div className="self-center ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="m-none bi bi-calendar4-range" viewBox="0 0 16 16"> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" /> <path d="M9 7.5a.5.5 0 0 1 .5-.5H15v2H9.5a.5.5 0 0 1-.5-.5v-1zm-2 3v1a.5.5 0 0 1-.5.5H1v-2h5.5a.5.5 0 0 1 .5.5z" /> </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 text-left sm:grid-cols-2 lg:gap-x-16">

                        {dataProduct.map(function (item, i) {
                            return (
                                <div key={i} className='cursor-pointer md:px-2 lg:px-6 mt-6 lg:mt-8 flex items-center transition-all duration-150 hover:bg-gray-50 hover:rounded-lg' variant="outlined" onClick={() => {
                                    getDataProductSlug(item.slug).then(() => {
                                        handleClickOpen();
                                        if (walletLogin !== null) {
                                            getDataUserSlug();
                                        }
                                    });
                                }}>
                                    <img className="w-16 h-16 md:w-24 md:h-24 mx-auto shrink-0 rounded-2xl lg:mx-0 m-none md:p-2" alt="" src={'https://api-launchy.rshme.codes/storage/' + item.logo} />
                                    <div className="lg:mt-0 ml-2 flex-1">
                                        <div className='flex items-center mt-2 mb-1'>
                                            <span className="block text-sm md:text-base font-bold">{item.name}</span>
                                            <a href={item.website} target="_blank" rel="noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ml-2 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" /> <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                                </svg>
                                            </a>
                                        </div>
                                        <span className="block text-xs md:text-sm font-medium truncate-summary mb-2">{item.tagline}</span>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-6 flex space-x-2'>
                                                {item.categories?.length <= 2 ?
                                                    <>
                                                        {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                            return (
                                                                <button key={i} onClick={(e) => { e.stopPropagation() }} className='bg-gray-100 px-2 py-2 rounded text-sm'>{x.name}</button>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <div className="categories-container">
                                                        <div className="wrap-categories space-x-1">
                                                            {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                return (
                                                                    <button key={i} onClick={(e) => { e.stopPropagation() }} className='bg-gray-100 px-2 py-2 rounded text-sm'>{x.name}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                }
                                                <button className='bg-gray-100 px-2 rounded flex items-center text-sm'>
                                                    <div style={{ width: '1.5vh' }}>
                                                        <img alt="" src={commentIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                    </div>
                                                    &nbsp;{item.comments?.length}
                                                </button>
                                            </div>
                                            <div className='col-span-6 space-x-1 text-end flex justify-end items-center'>
                                                <span className='px-2 font-semibold'>{item.votes_count}</span>
                                                {walletLogin !== null ?
                                                    <>
                                                        {item.votes.find((x) => x.wallet_address === walletLogin) ?
                                                            <button onClick={(e) => { e.stopPropagation(); voteProduct(item.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                </svg>
                                                            </button>
                                                            :
                                                            <button onClick={(e) => { e.stopPropagation(); voteProduct(item.slug) }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                </svg>
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <button onClick={() => {
                                                        setIsToast(true);
                                                        setToastMessage('Connect your wallet first.');
                                                    }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                        </svg>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}

                    </div>
                    <div className='w-full text-end mt-4 lg:mt-2'>
                        <button onClick={() => {
                            setynftMore(ynftMore + 10);
                        }}>
                            <span className='text-base font-extrabold'>Show more</span>
                        </button>
                    </div>

                    {mobileQuery ?
                        <Dialog
                            fullScreen
                            open={openDiscover}
                            onClose={() => {
                                handleClose();
                                setDataProductShow([]);
                            }}
                        >
                            <DialogContent>
                                {dataProductShow.map((item, i) => {
                                    return (
                                        <div className='relative' id='top-modal' key={i}>
                                            <button onClick={handleClose} className="block lg:hidden text-end close-modal-mobile">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </button>
                                            <img className="block md:hidden object-cover rounded-xl w-20 h-20" src={'https://api-launchy.rshme.codes/storage/' + item?.logo} alt="" />
                                            <div className='md:px-4 flex'>
                                                <div className="items-center self-center">
                                                    <img className="hidden md:block object-cover rounded-2xl w-40 h-40 md:p-2" style={{ height: 'fit-content' }} src={'https://api-launchy.rshme.codes/storage/' + item?.logo} alt="" />
                                                </div>
                                                <div className='w-full md:pl-4'>
                                                    <div className='grid md:flex pt-2'>
                                                        <span className='w-full font-extrabold m-none text-3xl tracking-widest'>{item?.name}</span>
                                                        <div className='text-start md:text-end mt-2 md:mt-0' style={{ minWidth: 'fit-content' }}>
                                                            <a href={item?.website} target="_blank" rel="noreferrer" className='drop-shadow-md text-base px-4 py-1 border-2 bg-core-900 rounded-lg text-white font-semibold inline-flex items-center'>Visit Site
                                                                <div className='ml-2 p-1' style={{ width: '3vh' }}>
                                                                    <img alt="" src={linkWhiteIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                                </div>
                                                            </a>
                                                            <button disabled>
                                                                <div className='space-x-1 text-end flex justify-end items-center px-2'>
                                                                    <span className='px-2 font-semibold'>{item?.votes_count}</span>

                                                                    {walletLogin !== null ?
                                                                        <>
                                                                            {item?.votes?.find((x) => x.wallet_address === walletLogin) ?
                                                                                <button onClick={(e) => { e.stopPropagation(); voteProductSlug(item?.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                    </svg>
                                                                                </button>
                                                                                :
                                                                                <button onClick={(e) => { e.stopPropagation(); voteProductSlug(item?.slug) }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <button onClick={() => {
                                                                            setIsToast(true);
                                                                            setToastMessage('Connect your wallet first.');
                                                                        }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                            </svg>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className='m-none text-base pb-2 text-gray-500'>{item?.website}</p>
                                                    <p className='m-none text-base pb-2 font-semibold'>{item?.summary}</p>
                                                    {item.categories?.length <= 2 ?
                                                        <>
                                                            {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                return (
                                                                    <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <div style={{ width: 'fit-content' }}>
                                                            <div className="space-x-1">
                                                                {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                    return (
                                                                        <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="py-8">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={20}
                                                    navigation={true}
                                                    modules={[Navigation]}
                                                    breakpoints={{
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1.5,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 2.5,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                    className="mySwiper"
                                                >
                                                    {(item?.youtube_url !== null && item?.youtube_url !== '') &&
                                                        <SwiperSlide>
                                                            <iframe title='url_youtube' className='rounded-lg youtube-frame' style={{ width: '100%' }}
                                                                src={urlYoutube}>
                                                            </iframe>
                                                        </SwiperSlide>
                                                    }
                                                    {item?.banners?.map(function (item, i) {
                                                        return (
                                                            <SwiperSlide>
                                                                <button className='w-full' onClick={() => {
                                                                    setGlobalState('isActiveSlider', true);
                                                                    setGlobalState('dataSlider', dataProductShow[0].banners);
                                                                    setGlobalState('indexSlider', i);
                                                                }}>
                                                                    <img src={'https://api-launchy.rshme.codes/storage/' + item} alt="" />
                                                                </button>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                    )}
                                                </Swiper>
                                            </div>
                                            <DialogContentText>
                                                <td dangerouslySetInnerHTML={{ __html: item?.description }} />
                                            </DialogContentText>
                                            <div className='mt-10'>
                                                <div className='flex items-center'>
                                                    {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                                        :
                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                    }
                                                    <div className="relative w-full px-2">
                                                        <input value={comment} onChange={(e) => { setComment(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                                    </div>
                                                    {walletLogin !== null ?
                                                        <button onClick={() => {
                                                            commentProduct(item?.slug);
                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                            Comment
                                                        </button>
                                                        :
                                                        <button onClick={() => {
                                                            setIsToast(true);
                                                            setToastMessage('Connect your wallet first.');
                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                            Comment
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                            {/* Comment */}
                                            {item?.comments?.map((itemComment, i) => {
                                                return (
                                                    <div className='mt-8' key={i}>
                                                        <div className='flex items-center'>
                                                            {itemComment?.user?.photo !== null ?
                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + itemComment?.user?.photo} alt="" />
                                                                :
                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                            }
                                                            <span className='font-medium ml-3'>{itemComment?.user.name}</span>
                                                        </div>
                                                        <div className='border-l-2 border-gray-300 px-4 mx-2 mt-2'>
                                                            {/* {commentStatus ?
                                                    <div>
                                                        <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                                        <button className='text-md' onClick={() => {
                                                            setCommentStatus(false)
                                                            setCommentNew('')
                                                        }}>cancel</button>
                                                    </div>
                                                    : */}
                                                            {i !== editIndex ?
                                                                <span className='font-base'>
                                                                    {itemComment?.comment}
                                                                </span>
                                                                :
                                                                <>
                                                                    {!isEdit ?
                                                                        <span className='font-base'>
                                                                            {itemComment?.comment}
                                                                        </span>
                                                                        :
                                                                        <div className='flex items-center'>
                                                                            <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                            <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                                editComment(itemComment?._id, item?.slug);
                                                                                setIsEdit(false);
                                                                            }}>Edit</button>
                                                                            <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                                setIsEdit(false)
                                                                                setCommentNew('')
                                                                            }}>Cancel</button>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {/* } */}
                                                            <div className='flex items-center space-x-6 mt-4'>
                                                                {/* <div className='flex justify-end items-center'>
                                                        <span className='text-sm font-bold text-gray-400 mr-2'>Upvote</span>
                                                        <div style={{ width: '2vh' }}>
                                                            <img alt="" src={upvoteIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                        </div>
                                                    </div> */}
                                                                <button onClick={() => {
                                                                    setReplyIndex(i);
                                                                    setCommentReply("@" + itemComment?.user?.name + " ");
                                                                    setIsReply(true);
                                                                }} className='text-sm font-bold text-gray-400'>Reply</button>
                                                                <span className='text-sm text-gray-400'>{moment(itemComment?.created_at).fromNow()}</span>

                                                                {dataUser?._id === itemComment?.user?._id &&
                                                                    <div className="relative md:mt-0" onMouseEnter={() => setTooltipStatus(i + 1)} onMouseLeave={() => setTooltipStatus(0)}>
                                                                        <div className="mr-2 cursor-pointer">
                                                                            <FaEllipsisH className='text-gray-400' />
                                                                        </div>
                                                                        {tooltipStatus === (i + 1) && (
                                                                            <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                                <div className='flex'>
                                                                                    <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                                        setEditIndex(i)
                                                                                        setIsEdit(true);
                                                                                        setTooltipStatus(0)
                                                                                        setCommentNew(itemComment?.comment);
                                                                                    }}>Edit</button>
                                                                                </div>
                                                                                <hr />
                                                                                <div className='flex'>
                                                                                    <button onClick={() => { deleteComment(itemComment?._id, item?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        )}{" "}
                                                                    </div>
                                                                }
                                                                {itemComment?.created_at !== itemComment?.updated_at &&
                                                                    <div className="mr-2 cursor-pointer">
                                                                        <div className='text-gray-400 text-xs'>Edited</div>
                                                                    </div>
                                                                }
                                                            </div>


                                                            {i !== replyIndex ? null :
                                                                <>
                                                                    {isReply &&
                                                                        <>
                                                                            <hr className='mt-6' />
                                                                            <div key={i} className='flex items-center my-2'>
                                                                                {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                                                                    :
                                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                                }
                                                                                <div className="relative w-full px-2">
                                                                                    <input value={commentReply} onChange={(e) => { setCommentReply(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                                                                </div>
                                                                                {walletLogin !== null &&
                                                                                    <div className='flex items-center'>
                                                                                        <button onClick={() => {
                                                                                            commentProductReply(itemComment?._id, item?.slug);
                                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                                                            Reply
                                                                                        </button>
                                                                                        <button onClick={() => {
                                                                                            setIsReply(false);
                                                                                            setCommentReply('');
                                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none">
                                                                                            Cancel
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <hr className='mb-6' />
                                                                        </>
                                                                    }
                                                                </>
                                                            }

                                                            {/* Reply Comment */}
                                                            {itemComment?.comments?.map((replyComment, index) => {
                                                                return (
                                                                    <div className='mt-4'>
                                                                        <div className='flex items-center'>
                                                                            {replyComment?.user?.photo !== null ?
                                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + replyComment?.user?.photo} alt="" />
                                                                                :
                                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                            }
                                                                            <span className='font-medium ml-3'>{replyComment?.user?.name}</span>
                                                                        </div>
                                                                        <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>

                                                                            {index !== editIndexReply ?
                                                                                <span className='font-base'>
                                                                                    {replyComment?.comment}
                                                                                </span>
                                                                                :
                                                                                <>
                                                                                    {!isEditReply ?
                                                                                        <span className='font-base'>
                                                                                            {replyComment?.comment}
                                                                                        </span>
                                                                                        :
                                                                                        <>
                                                                                            {replyComment?._id === idComment ?
                                                                                                <div className='flex items-center'>
                                                                                                    <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                                                    <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                                                        editComment(replyComment?._id, item?.slug);
                                                                                                        setIsEditReply(false);
                                                                                                    }}>Edit</button>
                                                                                                    <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                                                        setIsEditReply(false)
                                                                                                        setCommentNew('')
                                                                                                    }}>Cancel</button>
                                                                                                </div>
                                                                                                :
                                                                                                <span className='font-base'>
                                                                                                    {replyComment?.comment}
                                                                                                </span>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>
                                                                            <span className='text-sm text-gray-400'>{moment(replyComment?.created_at).fromNow()}</span>

                                                                            {dataUser?._id === replyComment?.user?._id &&
                                                                                <div key={index} className="relative md:mt-0" onMouseEnter={() => { setIdComment(replyComment?._id); setTooltipStatus(index + 1 + 'r'); }} onMouseLeave={() => setTooltipStatus(0)}>
                                                                                    <div className="mr-2 cursor-pointer">
                                                                                        <FaEllipsisH className='text-gray-400' />
                                                                                    </div>
                                                                                    {replyComment?._id === idComment &&
                                                                                        <>
                                                                                            {tooltipStatus === (index + 1 + 'r') && (
                                                                                                <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                                                    <div className='flex'>
                                                                                                        <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                                                            setEditIndexReply(index)
                                                                                                            setIsEditReply(true);
                                                                                                            setTooltipStatus(0)
                                                                                                            setCommentNew(replyComment?.comment);
                                                                                                        }}>Edit</button>
                                                                                                    </div>
                                                                                                    <hr />
                                                                                                    <div className='flex'>
                                                                                                        <button onClick={() => { deleteComment(replyComment?._id, item?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}{" "}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {replyComment?.created_at !== replyComment?.updated_at &&
                                                                                <div className="mr-2 cursor-pointer">
                                                                                    <div className='text-gray-400 text-xs'>Edited</div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    )
                                }
                                )}

                                <div className='pt-8 py-4 my-4'>
                                    <span className='text-2xl font-bold mb-8'>Similar Project</span>

                                    <div className="grid grid-cols-1 mt-8 sm:px-0 text-left sm:grid-cols-2 md:gap-x-6 lg:gap-x-16">

                                        {dataSimilarProduct?.map((data, index) => {
                                            return (
                                                <div className='py-2 px-1 mb-2 flex rounded-lg items-center transition-all duration-150 hover:bg-gray-100' variant="outlined"
                                                    onClick={() => {
                                                        getDataProductSlug(data?.slug).then(() => {
                                                            handleClickOpen();
                                                            document.getElementById('top-modal').scrollIntoView();
                                                        });
                                                    }}
                                                >
                                                    <img className="w-16 h-16 md:w-24 md:h-24 shrink-0 rounded-2xl mx-0 md:p-2" alt="" src={"https://api-launchy.rshme.codes/storage/" + data.logo} />
                                                    <div className="mt-6 mt-0 ml-2 flex-1">
                                                        <div className='flex items-center mt-2 mb-1'>
                                                            <span className="block text-sm md:text-base font-bold">{data.name}</span>
                                                            <a href={data.website} target="_blank" rel="noreferrer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ml-2 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" /> <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <span className="block text-xs md:text-sm font-medium truncate-summary mb-2">{data.tagline}</span>
                                                        <div className='grid grid-cols-12'>
                                                            <div className='col-span-6 flex space-x-2'>
                                                                {data.categories?.length <= 2 ?
                                                                    <>
                                                                        {data.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                            return (
                                                                                <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <div className="categories-container">
                                                                        <div className="wrap-categories space-x-1">
                                                                            {data.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                                return (
                                                                                    <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <button className='bg-gray-100 px-2 rounded flex items-center text-sm'>
                                                                    <div style={{ width: '2vh' }}>
                                                                        <img alt="" src={commentIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                                    </div>
                                                                    &nbsp;{data.comments?.length}
                                                                </button>
                                                            </div>
                                                            <div className='col-span-6 space-x-1 text-end flex justify-end items-center'>
                                                                <span className='px-2 font-semibold'>{data.votes_count}</span>

                                                                {walletLogin !== null ?
                                                                    <>
                                                                        {data?.votes?.find((x) => x.wallet_address === walletLogin) ?
                                                                            <button onClick={(e) => { e.stopPropagation(); voteProductSlug(data?.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                </svg>
                                                                            </button>
                                                                            :
                                                                            <button onClick={(e) => { e.stopPropagation(); voteProductSlug(data?.slug) }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                </svg>
                                                                            </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <button onClick={() => {
                                                                        setIsToast(true);
                                                                        setToastMessage('Connect your wallet first.');
                                                                    }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                        </svg>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                    <div className='w-full text-end mt-4 lg:mt-2'>
                                        <button onClick={() => { setsmlrMore(smlrMore + 4) }}>
                                            <span className='text-base font-extrabold'>Show more</span>
                                        </button>
                                    </div>
                                </div>

                                {/* <Button onClick={handleClose}>Close</Button> */}
                            </DialogContent >
                        </Dialog >
                        :
                        <Dialog
                            fullWidth={true}
                            maxWidth='lg'
                            open={openDiscover}
                            onClose={() => {
                                handleClose();
                                setDataProductShow([]);
                            }}
                        >
                            <DialogContent>
                                {dataProductShow.map((item, i) => {
                                    return (
                                        <div className='relative' id='top-modal' key={i}>
                                            <button onClick={handleClose} className="block lg:hidden text-end">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </button>
                                            <img className="block md:hidden object-cover rounded-xl w-20 h-20" src={'https://api-launchy.rshme.codes/storage/' + item?.logo} alt="" />
                                            <div className='md:px-4 flex'>
                                                <div className="items-center self-center">
                                                    <img className="hidden md:block object-cover rounded-2xl w-40 h-40 md:p-2" style={{ height: 'fit-content' }} src={'https://api-launchy.rshme.codes/storage/' + item?.logo} alt="" />
                                                </div>
                                                <div className='w-full md:pl-4'>
                                                    <div className='grid md:flex pt-2'>
                                                        <span className='w-full font-extrabold m-none text-3xl tracking-widest'>{item?.name}</span>
                                                        <div className='text-start md:text-end mt-2 md:mt-0' style={{ minWidth: 'fit-content' }}>
                                                            <a href={item?.website} target="_blank" rel="noreferrer" className='drop-shadow-md text-base px-4 py-1 border-2 bg-core-900 rounded-lg text-white font-semibold inline-flex items-center'>Visit Site
                                                                <div className='ml-2 p-1' style={{ width: '3vh' }}>
                                                                    <img alt="" src={linkWhiteIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                                </div>
                                                            </a>
                                                            <button disabled>
                                                                <div className='space-x-1 text-end flex justify-end items-center px-2'>
                                                                    <span className='px-2 font-semibold'>{item?.votes_count}</span>

                                                                    {walletLogin !== null ?
                                                                        <>
                                                                            {item?.votes?.find((x) => x.wallet_address === walletLogin) ?
                                                                                <button onClick={(e) => { e.stopPropagation(); voteProductSlug(item?.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                    </svg>
                                                                                </button>
                                                                                :
                                                                                <button onClick={(e) => { e.stopPropagation(); voteProductSlug(item?.slug) }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                    </svg>
                                                                                </button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <button onClick={() => {
                                                                            setIsToast(true);
                                                                            setToastMessage('Connect your wallet first.');
                                                                        }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                            </svg>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className='m-none text-base pb-2 text-gray-500'>{item?.website}</p>
                                                    <p className='m-none text-base pb-2 font-semibold'>{item?.summary}</p>
                                                    {item.categories?.length <= 2 ?
                                                        <>
                                                            {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                return (
                                                                    <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <div style={{ width: 'fit-content' }}>
                                                            <div className="space-x-1">
                                                                {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                    return (
                                                                        <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="py-8">
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={20}
                                                    navigation={true}
                                                    modules={[Navigation]}
                                                    breakpoints={{
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1.5,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 2.5,
                                                            spaceBetween: 20,
                                                        },
                                                    }}
                                                    className="mySwiper"
                                                >
                                                    {(item?.youtube_url !== null && item?.youtube_url !== '') &&
                                                        <SwiperSlide>
                                                            <iframe title='url_youtube' className='rounded-lg youtube-frame' style={{ width: '100%' }}
                                                                src={urlYoutube}>
                                                            </iframe>
                                                        </SwiperSlide>
                                                    }
                                                    {item?.banners?.map(function (item, i) {
                                                        return (
                                                            <SwiperSlide className='m-swiper-slide'>
                                                                <button className='w-full' onClick={() => {
                                                                    setGlobalState('isActiveSlider', true);
                                                                    setGlobalState('dataSlider', dataProductShow[0].banners);
                                                                    setGlobalState('indexSlider', i);
                                                                }}>
                                                                    <img src={'https://api-launchy.rshme.codes/storage/' + item} alt="" />
                                                                </button>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                    )}
                                                </Swiper>
                                            </div>
                                            <DialogContentText>
                                                <td dangerouslySetInnerHTML={{ __html: item?.description }} />
                                            </DialogContentText>
                                            <div className='mt-10'>
                                                <div className='flex items-center'>
                                                    {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                                        :
                                                        <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                    }
                                                    <div className="relative w-full px-2">
                                                        <input value={comment} onChange={(e) => { setComment(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                                    </div>
                                                    {walletLogin !== null ?
                                                        <button onClick={() => {
                                                            commentProduct(item?.slug);
                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                            Comment
                                                        </button>
                                                        :
                                                        <button onClick={() => {
                                                            setIsToast(true);
                                                            setToastMessage('Connect your wallet first.');
                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                            Comment
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                            {/* Comment */}
                                            {item?.comments?.map((itemComment, i) => {
                                                return (
                                                    <div className='mt-8' key={i}>
                                                        <div className='flex items-center'>
                                                            {itemComment?.user?.photo !== null ?
                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + itemComment?.user?.photo} alt="" />
                                                                :
                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                            }
                                                            <span className='font-medium ml-3'>{itemComment?.user.name}</span>
                                                        </div>
                                                        <div className='border-l-2 border-gray-300 px-4 mx-2 mt-2'>
                                                            {/* {commentStatus ?
                                                    <div>
                                                        <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                                        <button className='text-md' onClick={() => {
                                                            setCommentStatus(false)
                                                            setCommentNew('')
                                                        }}>cancel</button>
                                                    </div>
                                                    : */}
                                                            {i !== editIndex ?
                                                                <span className='font-base'>
                                                                    {itemComment?.comment}
                                                                </span>
                                                                :
                                                                <>
                                                                    {!isEdit ?
                                                                        <span className='font-base'>
                                                                            {itemComment?.comment}
                                                                        </span>
                                                                        :
                                                                        <div className='flex items-center'>
                                                                            <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                            <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                                editComment(itemComment?._id, item?.slug);
                                                                                setIsEdit(false);
                                                                            }}>Edit</button>
                                                                            <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                                setIsEdit(false)
                                                                                setCommentNew('')
                                                                            }}>Cancel</button>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {/* } */}
                                                            <div className='flex items-center space-x-6 mt-4'>
                                                                <button onClick={() => {
                                                                    setReplyIndex(i);
                                                                    setCommentReply("@" + itemComment?.user?.name + " ");
                                                                    setIsReply(true);
                                                                }} className='text-sm font-bold text-gray-400'>Reply</button>
                                                                <span className='text-sm text-gray-400'>{moment(itemComment?.created_at).fromNow()}</span>

                                                                {dataUser?._id === itemComment?.user?._id &&
                                                                    <div className="relative md:mt-0" onMouseEnter={() => setTooltipStatus(i + 1)} onMouseLeave={() => setTooltipStatus(0)}>
                                                                        <div className="mr-2 cursor-pointer">
                                                                            <FaEllipsisH className='text-gray-400' />
                                                                        </div>
                                                                        {tooltipStatus === (i + 1) && (
                                                                            <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                                <div className='flex'>
                                                                                    <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                                        setEditIndex(i)
                                                                                        setIsEdit(true);
                                                                                        setTooltipStatus(0)
                                                                                        setCommentNew(itemComment?.comment);
                                                                                    }}>Edit</button>
                                                                                </div>
                                                                                <hr />
                                                                                <div className='flex'>
                                                                                    <button onClick={() => { deleteComment(itemComment?._id, item?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                                </div>
                                                                            </div>
                                                                        )}{" "}
                                                                    </div>
                                                                }
                                                                {itemComment?.created_at !== itemComment?.updated_at &&
                                                                    <div className="mr-2 cursor-pointer">
                                                                        <div className='text-gray-400 text-xs'>Edited</div>
                                                                    </div>
                                                                }
                                                            </div>


                                                            {i !== replyIndex ? null :
                                                                <>
                                                                    {isReply &&
                                                                        <>
                                                                            <hr className='mt-6' />
                                                                            <div key={i} className='flex items-center my-2'>
                                                                                {dataUser?.photo !== null && dataUser?.photo !== undefined ?
                                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + dataUser?.photo} alt="" />
                                                                                    :
                                                                                    <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                                }
                                                                                <div className="relative w-full px-2">
                                                                                    <input value={commentReply} onChange={(e) => { setCommentReply(e.target.value) }} type="text" className="focus:ring-core-900 text-black border-none text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="Write a comment" />
                                                                                </div>
                                                                                {walletLogin !== null &&
                                                                                    <div className='flex items-center'>
                                                                                        <button onClick={() => {
                                                                                            commentProductReply(itemComment?._id, item?.slug);
                                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                                                                            Reply
                                                                                        </button>
                                                                                        <button onClick={() => {
                                                                                            setIsReply(false);
                                                                                            setCommentReply('');
                                                                                        }} type="submit" className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none">
                                                                                            Cancel
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <hr className='mb-6' />
                                                                        </>
                                                                    }
                                                                </>
                                                            }

                                                            {/* Reply Comment */}
                                                            {itemComment?.comments?.map((replyComment, index) => {
                                                                return (
                                                                    <div className='mt-4'>
                                                                        <div className='flex items-center'>
                                                                            {replyComment?.user?.photo !== null ?
                                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={'https://api-launchy.rshme.codes/storage/' + replyComment?.user?.photo} alt="" />
                                                                                :
                                                                                <img className="flex-shrink-0 object-cover rounded-full w-9 h-9 m-none" src={userIcon} alt="" />
                                                                            }
                                                                            <span className='font-medium ml-3'>{replyComment?.user?.name}</span>
                                                                        </div>
                                                                        <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>

                                                                            {index !== editIndexReply ?
                                                                                <span className='font-base'>
                                                                                    {replyComment?.comment}
                                                                                </span>
                                                                                :
                                                                                <>
                                                                                    {!isEditReply ?
                                                                                        <span className='font-base'>
                                                                                            {replyComment?.comment}
                                                                                        </span>
                                                                                        :
                                                                                        <>
                                                                                            {replyComment?._id === idComment ?
                                                                                                <div className='flex items-center'>
                                                                                                    <input value={commentNew} onChange={(e) => { setCommentNew(e.target.value) }} type="text" className="mr-2 border-core-900 focus:ring-core-900 text-black border-1 text-sm rounded-lg block w-full pr-10 p-2.5 dark:placeholder-gray-900" placeholder="" />
                                                                                                    <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900" onClick={() => {
                                                                                                        editComment(replyComment?._id, item?.slug);
                                                                                                        setIsEditReply(false);
                                                                                                    }}>Edit</button>
                                                                                                    <button className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-base text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300 focus:ring-4 focus:outline-none" onClick={() => {
                                                                                                        setIsEditReply(false)
                                                                                                        setCommentNew('')
                                                                                                    }}>Cancel</button>
                                                                                                </div>
                                                                                                :
                                                                                                <span className='font-base'>
                                                                                                    {replyComment?.comment}
                                                                                                </span>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className='flex items-center space-x-6 px-4 mx-2 mt-2'>
                                                                            <span className='text-sm text-gray-400'>{moment(replyComment?.created_at).fromNow()}</span>

                                                                            {dataUser?._id === replyComment?.user?._id &&
                                                                                <div key={index} className="relative md:mt-0" onMouseEnter={() => { setIdComment(replyComment?._id); setTooltipStatus(index + 1 + 'r'); }} onMouseLeave={() => setTooltipStatus(0)}>
                                                                                    <div className="mr-2 cursor-pointer">
                                                                                        <FaEllipsisH className='text-gray-400' />
                                                                                    </div>
                                                                                    {replyComment?._id === idComment &&
                                                                                        <>
                                                                                            {tooltipStatus === (index + 1 + 'r') && (
                                                                                                <div role="tooltip" className="z-20 space-y-2 -mt-20 w-auto absolute transition duration-150 ease-in-out left-0 ml-5 drop-shadow bg-white p-4 rounded">
                                                                                                    <div className='flex'>
                                                                                                        <button className="text-base rounded-lg text-core-900" onClick={() => {
                                                                                                            setEditIndexReply(index)
                                                                                                            setIsEditReply(true);
                                                                                                            setTooltipStatus(0)
                                                                                                            setCommentNew(replyComment?.comment);
                                                                                                        }}>Edit</button>
                                                                                                    </div>
                                                                                                    <hr />
                                                                                                    <div className='flex'>
                                                                                                        <button onClick={() => { deleteComment(replyComment?._id, item?.slug) }} className="text-base rounded-lg text-core-900">Delete</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}{" "}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            {replyComment?.created_at !== replyComment?.updated_at &&
                                                                                <div className="mr-2 cursor-pointer">
                                                                                    <div className='text-gray-400 text-xs'>Edited</div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    )
                                }
                                )}

                                <div className='pt-8 py-4 my-4'>
                                    <span className='text-2xl font-bold mb-8'>Similar Project</span>

                                    <div className="grid grid-cols-1 mt-8 sm:px-0 text-left sm:grid-cols-2 md:gap-x-6 lg:gap-x-16">

                                        {dataSimilarProduct?.map((data, index) => {
                                            return (
                                                <div className='py-2 px-1 mb-2 flex rounded-lg items-center transition-all duration-150 hover:bg-gray-100' variant="outlined"
                                                    onClick={() => {
                                                        getDataProductSlug(data?.slug).then(() => {
                                                            handleClickOpen();
                                                            document.getElementById('top-modal').scrollIntoView();
                                                        });
                                                    }}
                                                >
                                                    <img className="w-16 h-16 md:w-24 md:h-24 shrink-0 rounded-2xl mx-0 md:p-2" alt="" src={"https://api-launchy.rshme.codes/storage/" + data.logo} />
                                                    <div className="mt-6 mt-0 ml-2 flex-1">
                                                        <div className='flex items-center mt-2 mb-1'>
                                                            <span className="block text-sm md:text-base font-bold">{data.name}</span>
                                                            <a href={data.website} target="_blank" rel="noreferrer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="ml-2 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" /> <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <span className="block text-xs md:text-sm font-medium truncate-summary mb-2">{data.tagline}</span>
                                                        <div className='grid grid-cols-12'>
                                                            <div className='col-span-6 flex space-x-2'>
                                                                {data.categories?.length <= 2 ?
                                                                    <>
                                                                        {data.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                            return (
                                                                                <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <div className="categories-container">
                                                                        <div className="wrap-categories space-x-1">
                                                                            {data.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                                                return (
                                                                                    <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-xs md:text-sm'>{x.name}</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <button className='bg-gray-100 px-2 rounded flex items-center text-sm'>
                                                                    <div style={{ width: '2vh' }}>
                                                                        <img alt="" src={commentIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                                                    </div>
                                                                    &nbsp;{data.comments?.length}
                                                                </button>
                                                            </div>
                                                            <div className='col-span-6 space-x-1 text-end flex justify-end items-center'>
                                                                <span className='px-2 font-semibold'>{data.votes_count}</span>
                                                                {walletLogin !== null ?
                                                                    <>
                                                                        {data?.votes?.find((x) => x.wallet_address === walletLogin) ?
                                                                            <button onClick={(e) => { e.stopPropagation(); voteProductSlug(data?.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                </svg>
                                                                            </button>
                                                                            :
                                                                            <button onClick={(e) => { e.stopPropagation(); voteProductSlug(data?.slug) }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                                </svg>
                                                                            </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <button onClick={() => {
                                                                        setIsToast(true);
                                                                        setToastMessage('Connect your wallet first.');
                                                                    }} className='rounded-full p-1' style={{ background: 'black' }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                                                        </svg>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })}
                                    </div>
                                    <div className='w-full text-end mt-4 lg:mt-2'>
                                        <button onClick={() => { setsmlrMore(smlrMore + 4) }}>
                                            <span className='text-base font-extrabold'>Show more</span>
                                        </button>
                                    </div>
                                </div>
                            </DialogContent >
                        </Dialog >
                    }

                    <Dialog
                        fullWidth={true}
                        maxWidth='sm'
                        open={openFilter}
                    >
                        <DialogContent>
                            <span className='text-xl font-bold'>Filter</span>
                            <ul className="grid w-full gap-6 md:grid-cols-2 my-2 mb-4">
                                <li>
                                    <input type="checkbox" id="last-week" value="last-week" onChange={(e) => {
                                        var elm = document.getElementById('clear-filter');
                                        if (elm.checked) {
                                            elm.click();
                                        }
                                        var elm2 = document.getElementById('this-week');
                                        if (elm2.checked) {
                                            elm2.click();
                                        }
                                        if (e.target.checked) {
                                            setFilterDateFrom(moment().subtract(1, 'weeks').startOf('isoweek').toDate());
                                            setFilterDateTo(moment().subtract(1, 'weeks').endOf('isoweek').toDate());
                                        } else {
                                            setFilterDateFrom(null);
                                            setFilterDateTo(null);
                                        }
                                    }} className="hidden peer" />
                                    <label for="last-week" className="inline-flex justify-center items-center w-full px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                                        <span>Last Week</span>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="this-week" value="this-week" onChange={(e) => {
                                        var elm = document.getElementById('clear-filter');
                                        if (elm.checked) {
                                            elm.click();
                                        }
                                        var elm2 = document.getElementById('last-week');
                                        if (elm2.checked) {
                                            elm2.click();
                                        }
                                        if (e.target.checked) {
                                            setFilterDateFrom(moment().subtract(0, 'weeks').startOf('isoweek').toDate());
                                            setFilterDateTo(moment().subtract(0, 'weeks').endOf('isoweek').toDate());
                                        } else {
                                            setFilterDateFrom(null);
                                            setFilterDateTo(null);
                                        }
                                    }} className="hidden peer" />
                                    <label for="this-week" className="inline-flex justify-center items-center w-full px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                                        <span>This Week</span>
                                    </label>
                                </li>
                            </ul>
                            <hr />
                            <div className='mt-4 mb-4 flex'>
                                <span className='self-center mx-2'>From</span>
                                <DatePicker style={{ borderRadius: '1rem' }} selected={filterDateFrom} onChange={(dateFrom) => {
                                    setFilterDateFrom(dateFrom);
                                    var elm = document.getElementById('clear-filter');
                                    if (elm.checked) {
                                        elm.checked = false;
                                    }
                                    var elm2 = document.getElementById('last-week');
                                    if (elm2.checked) {
                                        elm2.checked = false;
                                    }
                                    var elm3 = document.getElementById('this-week');
                                    if (elm3.checked) {
                                        elm3.checked = false;
                                    }
                                }} />
                                <span className='self-center mx-2'>To</span>
                                <DatePicker style={{ borderRadius: '1rem' }} minDate={filterDateFrom} selected={filterDateTo} onChange={(dateTo) => {
                                    setFilterDateTo(dateTo);
                                    var elm = document.getElementById('clear-filter');
                                    if (elm.checked) {
                                        elm.checked = false;
                                    }
                                    var elm2 = document.getElementById('last-week');
                                    if (elm2.checked) {
                                        elm2.checked = false;
                                    }
                                    var elm3 = document.getElementById('this-week');
                                    if (elm3.checked) {
                                        elm3.checked = false;
                                    }
                                }} />
                            </div>
                            <hr />
                            <div className='mt-4 mb-6' style={{ width: 'fit-content' }}>
                                <input type="checkbox" id="clear-filter" value="clear-filter" onChange={(e) => {
                                    if (e.target.checked) {
                                        var elm = document.getElementById('last-week');
                                        if (elm.checked) {
                                            elm.click();
                                        }
                                        var elm2 = document.getElementById('this-week');
                                        if (elm2.checked) {
                                            elm2.click();
                                        }
                                        setFilterDateFrom(null);
                                        setFilterDateTo(null);
                                    }
                                }} className="hidden peer" />
                                <label for="clear-filter" className="inline-flex justify-center items-center w-full px-2 py-1 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                                    <span>Clear</span>
                                </label>
                            </div>
                            <div className='md:space-x-2 space-y-1 md:space-y-0'>
                                <button onClick={() => {
                                    getDataProduct();
                                    setOpenFilter(false);
                                }} type="button" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
                                <button onClick={() => {
                                    setOpenFilter(false);
                                }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Cancel</button>
                            </div>
                        </DialogContent >
                    </Dialog >

                    {
                        isToast &&
                        <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                            <div className="text-sm font-normal flex items-center">
                                <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                                </svg>
                                <span>{toastMessage}</span>
                            </div>
                        </div>
                    }
                </div>

            </>
        );
}

export default Discover;

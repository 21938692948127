import '../App.css';
import { useEffect, useState } from 'react';
import { FaDiscord } from 'react-icons/fa';
import * as EmailValidator from 'email-validator';
import axios from '../utils/axiosInt';
import { setGlobalState, useGlobalState } from '../utils/globalState';

function Subscribe() {
    const [isSubsDefault] = useGlobalState("isSubsDefault")
    const [isActiveProfile] = useGlobalState("isActiveProfile")
    const [dataProfile] = useGlobalState("dataProfile")
    const [walletLogin] = useGlobalState("walletAddress");
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [emailUser, setEmailUser] = useState('');

    const getDataUser = async () => {
        const response = await axios.get(`/user/${walletLogin}`);
        setGlobalState("dataProfile", response.data.data);
    }

    const regSubs = async (email) => {
        try {
            let data = {
                "email": email,
                "reactivate_existing": false,
                "send_welcome_email": true,
                "utm_medium": "organic",
                "referring_site": "launchy.app"
            }

            await axios.post(`/user/subscribe`, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(()=>{
                setIsToast(true)
                setToastMessage('Email successfully subscribed!')
                setEmailUser('');
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const editDataUser = async (status, email) => {
        let subsStatus;
        if (status === 'subs') {
            subsStatus = true;
        } else if (status === 'unsubs') {
            subsStatus = false;
        }

        try {
            let data = {
                "email": email,
                "reactivate_existing": false,
                "send_welcome_email": true,
                "utm_medium": "organic",
                "referring_site": "launchy.app"
            }

            await axios.post(`/user/subscribe`, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(async (result) => {
                let data = {
                    "is_subscribed": subsStatus,
                    "email": email,
                }

                await axios.put(`/user/${walletLogin}`, data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (status === 'subs') {
                    setIsToast(true);
                    setToastMessage('Email successfully subscribed!');
                } else if (status === 'unsubs') {
                    setIsToast(true);
                    setToastMessage('Email successfully unsubscribe!');
                }
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
        getDataUser();
    }

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    if (isActiveProfile === true) {
        if (walletLogin === null) {
            return (
                <>
                    <aside className="lg:col-span-3 lg:order-last lg:self-start lg:top-8">
                        <div className="overflow-hidden bg-white border-b border-gray-400 pb-5">
                            <div className="mt-12 flex-1 max-w-sm mx-auto lg:max-w-md"><>
                                <h1 className="mt-1 text-xl font-bold text-center text-gray-900 font-pj lg:text-start">Keep up to date with the latest web3 products, strategies, and trends.</h1>
                                <div className="mt-10">
                                    <div className="space-y-4">
                                        <div>
                                            <div className="relative">

                                                <input
                                                    value={emailUser} onChange={(e) => { setEmailUser(e.target.value) }}
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="Your email"
                                                    className="
                                                block
                                                w-full
                                                py-3
                                                pr-4
                                                overflow-hidden
                                                text-base
                                                font-normal
                                                text-gray-900
                                                placeholder-gray-600
                                                transition-all
                                                duration-200
                                                border border-gray-300
                                                caret-gray-900
                                                rounded-xl
                                                focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                                font-pj
                                            "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative mt-8">
                                        <button
                                            onClick={() => {
                                                if (walletLogin !== null) {
                                                    getDataUser();
                                                } else {
                                                    let mailChecker = EmailValidator.validate(emailUser);
                                                    if (mailChecker) {
                                                        regSubs(emailUser);
                                                    } else {
                                                        setIsToast(true);
                                                        setToastMessage('Please enter your email correctly.');
                                                    }
                                                }
                                            }}
                                            type="submit"
                                            className="relative flex items-center justify-center w-full px-8 py-2 text-base font-bold text-white transition-all duration-200 bg-core-900 border border-transparent rounded-lg font-pj"
                                        >
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </>

                            </div>
                        </div>

                        <button className="justify-center inline-flex items-center w-full mt-4 py-2.5 px-3 mr-2 text-sm font-medium text-gray-600 rounded-lg">
                            <FaDiscord size={24} className='mr-2' />Join us on Discord
                        </button>
                    </aside>
                    {
                        isToast &&
                        <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                            <div className="text-sm font-normal flex items-center">
                                <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                                </svg>
                                <span>{toastMessage}</span>
                            </div>
                        </div>
                    }
                </>
            );
        }

        if (dataProfile.length !== 0) {
            return (
                <>
                    <aside className="lg:col-span-3 lg:order-last lg:self-start lg:top-8">
                        <div className="overflow-hidden bg-white border-b border-gray-400 pb-5">
                            <div className="mt-12 flex-1 max-w-sm mx-auto lg:max-w-md">
                                {dataProfile?.is_subscribed !== true ?
                                    <>
                                        <h1 className="mt-1 text-xl font-bold text-center text-gray-900 font-pj lg:text-start">Keep up to date with the latest web3 products, strategies, and trends.</h1>
                                        <div className="mt-10">
                                            <div className="space-y-4">
                                                <div>
                                                    <div className="relative">

                                                        <input
                                                            value={emailUser} onChange={(e) => { setEmailUser(e.target.value) }}
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            placeholder="Your email"
                                                            className="
                                                        block
                                                        w-full
                                                        py-3
                                                        pr-4
                                                        overflow-hidden
                                                        text-base
                                                        font-normal
                                                        text-gray-900
                                                        placeholder-gray-600
                                                        transition-all
                                                        duration-200
                                                        border border-gray-300
                                                        caret-gray-900
                                                        rounded-xl
                                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                                        font-pj
                                                    "
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div onClick={() => {
                                                let mailChecker = EmailValidator.validate(emailUser);
                                                if (mailChecker) {
                                                    editDataUser('subs', emailUser);
                                                } else {
                                                    setIsToast(true);
                                                    setToastMessage('Please enter your email correctly.');
                                                }
                                            }
                                            } className="relative mt-8">
                                                <button
                                                    type="submit"
                                                    className="relative flex items-center justify-center w-full px-8 py-2 text-base font-bold text-white transition-all duration-200 bg-core-900 border border-transparent rounded-lg font-pj"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        <button className="justify-center inline-flex items-center w-full mt-4 py-2.5 px-3 mr-2 text-sm font-medium text-gray-600 rounded-lg">
                            <FaDiscord size={24} className='mr-2' />Join us on Discord
                        </button>
                    </aside>
                    {
                        isToast &&
                        <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                            <div className="text-sm font-normal flex items-center">
                                <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                                </svg>
                                <span>{toastMessage}</span>
                            </div>
                        </div>
                    }
                </>
            );
        }
    } else {
        if (isSubsDefault === true) {
            return (
                <>
                    <aside className="lg:col-span-3 lg:order-last lg:self-start lg:top-8">
                        <div className="overflow-hidden bg-white border-b border-gray-400 pb-5">
                            <div className="mt-12 flex-1 max-w-sm mx-auto lg:max-w-md"><>
                                <h1 className="mt-1 text-xl font-bold text-center text-gray-900 font-pj lg:text-start">Keep up to date with the latest web3 products, strategies, and trends.</h1>
                                <div className="mt-10">
                                    <div className="space-y-4">
                                        <div>
                                            <div className="relative">

                                                <input
                                                    value={emailUser} onChange={(e) => { setEmailUser(e.target.value) }}
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="Your email"
                                                    className="
                                                block
                                                w-full
                                                py-3
                                                pr-4
                                                overflow-hidden
                                                text-base
                                                font-normal
                                                text-gray-900
                                                placeholder-gray-600
                                                transition-all
                                                duration-200
                                                border border-gray-300
                                                caret-gray-900
                                                rounded-xl
                                                focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                                font-pj
                                            "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative mt-8">
                                        <button
                                            onClick={() => {
                                                if (walletLogin !== null) {
                                                    getDataUser();
                                                } else {
                                                    let mailChecker = EmailValidator.validate(emailUser);
                                                    if (mailChecker) {
                                                        regSubs(emailUser);
                                                    } else {
                                                        setIsToast(true);
                                                        setToastMessage('Please enter your email correctly.');
                                                    }
                                                }
                                            }}
                                            type="submit"
                                            className="relative flex items-center justify-center w-full px-8 py-2 text-base font-bold text-white transition-all duration-200 bg-core-900 border border-transparent rounded-lg font-pj"
                                        >
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </>

                            </div>
                        </div>

                        <button className="justify-center inline-flex items-center w-full mt-4 py-2.5 px-3 mr-2 text-sm font-medium text-gray-600 rounded-lg">
                            <FaDiscord size={24} className='mr-2' />Join us on Discord
                        </button>
                    </aside>
                    {
                        isToast &&
                        <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                            <div className="text-sm font-normal flex items-center">
                                <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                                </svg>
                                <span>{toastMessage}</span>
                            </div>
                        </div>
                    }
                </>
            );
        }
    }
}

export default Subscribe;

import '../../App.css';

import WWD from './WWD';
import Story from './Story';
import Team from './Team';
import FAQ from './FAQ';

import banner from '../../assets/banner/about.png';

function About() {
  return (
    <div>
        <img className='w-full object-cover md:object-contain h-80 md:h-full' src={banner} alt='' />

        <WWD />
        <Story />
        <Team />
        <FAQ />

        {/* <footer className='bg-black py-1'>
            <p className="text-md font-semibold text-center text-white m-none">© 2021 Copyright: Launchy</p>
        </footer> */}
    </div>
  );
}

export default About;

import '../../App.css';
import axios from '../../utils/axiosInt';
import { useEffect, useState } from 'react';
import { FaWallet, FaCoins, FaCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ethers } from "ethers";
import { setGlobalState, useGlobalState } from '../../utils/globalState';
import useMediaQuery from "@mui/material/useMediaQuery";

import linkIcon from '../../assets/icon/link.png';
import commentIcon from '../../assets/icon/comment.png';
import userIcon from '../../assets/icon/user.png';
import twitterIcon from '../../assets/icon/twitter.png';
import linkedinIcon from '../../assets/icon/linkedin.png';
import websiteIcon from '../../assets/icon/website.png';

function ProfileDetail() {
    let navigate = useNavigate();
    const mobileQuery = useMediaQuery("(max-width:767px)");
    const [isWait, setIsWait] = useState(false);
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [openEditProfile, setOpenEditProfile] = useState(false);
    // const [dataProfile, setDataProfile] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);

    const [profileUser, setProfileUser] = useState(null);
    const [bannerUser, setBannerUser] = useState(null);
    const [nameUser, setNameUser] = useState('');
    const [bioUser, setBioUser] = useState('');
    const [aboutUser, setAboutUser] = useState('');
    const [twitterUser, setTwitterUser] = useState('');
    const [linkedInUser, setLinkedInUser] = useState('');
    const [websiteUser, setWebsiteUser] = useState('');
    const [interestUser, setInterestUser] = useState([]);

    // Global Variable
    const [dataProfile] = useGlobalState("dataProfile");
    const [walletLogin] = useGlobalState("walletAddress");

    const getDataUser = async () => {
        const response = await axios.get(`/user/${walletLogin}`);
        setGlobalState("dataProfile", response.data.data);
    }

    const getDataCategory = async () => {
        const response = await axios.get(`/category`).catch(function (error) {
            console.log(error);
        });
        setDataCategory(response.data.data);
    }

    const editDataUser = async () => {
        setIsWait(true);

        let dataSosmed = [];
        const response = await axios.get(`/social-media`);
        var dataTwitter = response.data.data.find(o => o.name === 'Twitter');
        var dataLinkedIn = response.data.data.find(o => o.name === 'LinkedIn');
        var dataWebsite = response.data.data.find(o => o.name === 'Personal Website');

        if (twitterUser !== "") {
            let twitCheck = twitterUser.includes('twitter.com');
            let dataTwit;
            if (twitCheck === false) {
                dataTwit = 'twitter.com/' + twitterUser
            } else {
                dataTwit = twitterUser
            }
            dataSosmed.push({
                id: dataTwitter._id,
                link: dataTwit
            })
        }
        if (linkedInUser !== "") {
            let linkedCheck = linkedInUser.includes('linkedin.com');
            let dataLinked;
            if (linkedCheck === false) {
                dataLinked = 'linkedin.com/' + linkedInUser
            } else {
                dataLinked = linkedInUser
            }
            dataSosmed.push({
                id: dataLinkedIn._id,
                link: dataLinked
            })
        }
        if (websiteUser !== "") {
            dataSosmed.push({
                id: dataWebsite._id,
                link: websiteUser
            })
        }

        let bio;
        if (bioUser === null) {
            bio = '';
        } else {
            bio = bioUser;
        }

        //edit data
        let data = {
            "name": nameUser,
            "bio": bio,
            "profile": aboutUser,
            "interests": interestUser,
            "links": dataSosmed
        }

        await axios.put(`/user/${walletLogin}`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(function (error) {
            console.log(error);
        });
        await getDataUser().then(() => {
            clearAllForm();
        });
        setOpenEditProfile(false)
        setIsWait(false);
    }

    const showEditProfile = async () => {
        console.log(dataProfile?.bio);
        // interest array object to array string
        var dataIntr = dataProfile?.interests.map(function (item) {
            return item['_id'];
        });

        var dataTwitter = dataProfile.links.find(o => o.social_media.name === 'Twitter');
        var dataLinkedIn = dataProfile.links.find(o => o.social_media.name === 'LinkedIn');
        var dataWebsite = dataProfile.links.find(o => o.social_media.name === 'Personal Website');

        setNameUser(dataProfile?.name);
        setBioUser(dataProfile?.bio);
        setAboutUser(dataProfile?.profile);
        setTwitterUser(dataTwitter !== undefined ? dataTwitter.link : '');
        setLinkedInUser(dataLinkedIn !== undefined ? dataLinkedIn.link : '');
        setWebsiteUser(dataWebsite !== undefined ? dataWebsite.link : '');
        setInterestUser(dataIntr);

        setOpenEditProfile(true)
    }

    const clearAllForm = async () => {
        setNameUser('');
        setBioUser('');
        setAboutUser('');
        setInterestUser([]);
        setProfileUser(null);
        setBannerUser(null);
    }

    const voteProduct = async (product) => {
        try {
            await axios.put(`/product/${product}/upvote`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            getDataUser();
        } catch (error) {
            console.log(error);
            setIsToast(true);
            setToastMessage('Something wrong, please try again later.');
        }
    }

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        if (walletLogin === null) {
            navigate('/');
        } else {
            getDataUser();
            getDataCategory();
        }
    }, [walletLogin]);

    useEffect(() => {
    }, [interestUser]);

    if (dataProfile.length !== 0)
        return (
            <div>
                <div className='bg-white drop-shadow-lg pb-4 mb-4 rounded-t-lg'>
                    <div className="relative mb-16">
                        {dataProfile?.img_banner === null ?
                            <div className="banner-profile m-none rounded-t-lg bg-gray-400" alt="" ></div>
                            :
                            <img className="banner-profile m-none rounded-t-lg" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.img_banner} alt="" />
                        }
                        {walletLogin !== null &&
                            <label for="dropzone-file-banner" className='absolute z-50 bg-gray-100/[.2] rounded-2xl edit-banner-profile'>
                                <svg className='p-1 text-white' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                    <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                </svg>
                                <input onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        const form_data = new FormData();
                                        form_data.append('img_banner', e.target.files[0]);
                                        await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }
                                        }).catch(function (error) {
                                            console.log(error);
                                        });
                                        getDataUser();
                                    }
                                }} id="dropzone-file-banner" type="file" className="hidden" />
                            </label>
                        }
                        <div className="absolute w-full px-4 max-w-xs ava-profile">
                            <div className="relative drop-shadow items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                {dataProfile?.photo === null ?
                                    <img className="object-cover bg-gray-100 rounded-full w-36 h-36" src={userIcon} alt="" />
                                    :
                                    <img className="object-cover bg-gray-100 rounded-full w-36 h-36" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.photo} alt="" />
                                }
                                {walletLogin !== null &&
                                    <label for="dropzone-file" className='absolute z-50 bg-gray-100/[.75] rounded-2xl edit-ava-profile'>
                                        <svg className='p-1 text-gray-600' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                            <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                        </svg>
                                        <input onChange={async (e) => {
                                            if (e.target.files[0]) {
                                                const form_data = new FormData();
                                                form_data.append('photo', e.target.files[0]);
                                                await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                                    headers: {
                                                        'Content-Type': 'multipart/form-data'
                                                    }
                                                }).catch(function (error) {
                                                    console.log(error);
                                                });
                                                getDataUser();
                                            }
                                        }} id="dropzone-file" type="file" className="hidden" />
                                    </label>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className='flex pt-2'>
                            <div className='block lg:flex items-center w-full'>
                                <h3 className='font-bold m-none'>{dataProfile?.name}</h3>
                                <div className='lg:mx-2 flex items-center'>
                                    <button data-bs-toggle="tooltip" title="Copy" onClick={async () => {
                                        const provider = new ethers.providers.Web3Provider(window.ethereum);
                                        const signer = provider.getSigner();
                                        const address = await signer.getAddress();
                                        await navigator.clipboard.writeText(address);
                                        setIsToast(true);
                                        setToastMessage('Wallet Address Copied!');
                                    }}>
                                        <FaWallet className='text-gray-400' />
                                    </button>
                                    <p className='m-none text-sm text-gray-400 px-1 truncate-wallet'>{dataProfile?.wallet_address}</p>
                                </div>
                            </div>
                            <div className='w-full text-end'>
                                <button onClick={() => {
                                    showEditProfile();
                                }}
                                    className='text-base px-4 py-1 border-2 border-core-900 rounded-lg text-core-900 font-bold'>
                                    <svg style={{ color: 'rgb(234, 51, 35)' }} width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                                        <path fill="#ea3323" fillRule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <p className='m-none text-base py-2'>{dataProfile?.profile}</p>

                        <div className='flex items-center space-x-1'>
                            <div className='flex items-center'>
                                <FaCoins size={16} className='text-gray-400' />
                                <p className='m-none text-sm text-gray-400 px-1'>{dataProfile?.point} Point</p>
                            </div>
                            <div className='flex items-center'>
                                <FaCalendarAlt size={16} className='text-gray-400' />
                                <p className='m-none text-sm text-gray-400 px-1'>Joined since {new Date(dataProfile?.created_at).getUTCFullYear()}</p>
                            </div>
                        </div>

                        {/* <div className='flex items-center space-x-4 mt-2 flex-wrap justify-center lg:justify-start lg:flex-nowrap'>
                        <div className='flex items-center'>
                            <p className='m-none text-base px-1 font-bold'>1589</p>
                            <p className='m-none text-base px-1'>Follower</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='m-none text-base px-1 font-bold'>409</p>
                            <p className='m-none text-base px-1'>Following</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='m-none text-base px-1 font-bold'>15.3k</p>
                            <p className='m-none text-base px-1'>Upvote</p>
                        </div>
                    </div> */}
                    </div>

                </div>

                <div className='bg-white drop-shadow-lg p-4 my-4'>
                    <div className='py-2'>
                        <h4 className='font-bold m-none'>Bio</h4>
                        <p className='m-none text-sm'>{dataProfile?.bio}</p>
                    </div>

                    <div className='py-2'>
                        <h4 className='font-bold m-none'>Links</h4>
                        <div className='flex items-center flex-wrap lg:justify-start lg:flex-nowrap'>
                            {dataProfile?.links.map(function (item, i) {
                                return (
                                    <a href={"//" + item.link} target="_blank" rel="noreferrer" className='flex items-center hover:bg-gray-100 p-1 rounded-md'>
                                        {item.social_media.name === 'Twitter' ?
                                            <img src={twitterIcon} className="m-none" alt="" style={{ width: '2vh' }} /> :
                                            item.social_media.name === 'LinkedIn' ?
                                                <img src={linkedinIcon} className="m-none" alt="" style={{ width: '2vh' }} />
                                                :
                                                <img src={websiteIcon} className="m-none" alt="" style={{ width: '2vh' }} />
                                        }
                                        <span className='text-sm px-2'>{item.social_media.name}</span>
                                    </a>
                                )
                            })}
                        </div>
                    </div>

                    <div className='py-2'>
                        <h4 className='font-bold m-none'>Interest</h4>
                        <div className='flex items-center space-x-2 flex-wrap lg:justify-start lg:flex-nowrap'>
                            {dataProfile?.interests.map(function (item, i) {
                                return <button key={i} className='bg-gray-100 px-2 py-1 rounded text-sm'>{item.name}</button>
                            })}
                        </div>
                    </div>
                </div>

                <div className='bg-white drop-shadow-lg p-4 my-4'>
                    <h4 className='font-bold m-none pb-4'>Recently Supported</h4>
                    {dataProfile?.votes?.map(function (item, i) {
                        return (
                            <div className="flex items-center justify-between py-2">
                                {item.logo !== null ?
                                    <img className="w-16 h-16 md:w-24 md:h-24 mx-auto shrink-0 rounded-2xl lg:mx-0 m-none md:p-2" src={`https://api-launchy.rshme.codes/storage/` + item.logo} alt="" />
                                    :
                                    <div className="flex-shrink-0 bg-gray-100 object-cover w-24 h-24 rounded-full m-none relative" alt="" >
                                        <div className='null-product'>
                                            <svg style={{ color: 'black' }} xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="gray"></path> <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" fill="gray"></path>
                                            </svg>
                                        </div>
                                    </div>
                                }
                                <div className="min-w-0 ml-3 mr-auto">
                                    <div className='flex items-center'>
                                        <p className="text-base font-semibold text-black truncate m-none py-1">{item.name}</p>
                                        <div className='ml-2 link-icon'>
                                            <img alt="" src={linkIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                        </div>
                                    </div>
                                    <p className="text-sm text-gray-600 truncate m-none py-1">{item.tagline}</p>
                                    <div className='flex space-x-2 py-1'>
                                        {item.categories?.length <= 2 ?
                                            <>
                                                {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                    return (
                                                        <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-sm'>{x.name}</button>
                                                    )
                                                })}
                                            </>
                                            :
                                            <div className="categories-container">
                                                <div className="wrap-categories space-x-1">
                                                    {item.categories?.sort(function (a, b) { return a.name.length - b.name.length }).map(function (x, i) {
                                                        return (
                                                            <button key={i} className='bg-gray-100 px-2 py-1.5 rounded text-sm'>{x.name}</button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        <button className='bg-gray-100 px-2 py-1 rounded flex items-center text-sm'>
                                            <div style={{ width: '1.5vh' }}>
                                                <img alt="" src={commentIcon} style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }} />
                                            </div>
                                            &nbsp;{item.comments?.length}
                                        </button>
                                    </div>
                                </div>
                                <div className='col-span-6 space-x-1 text-end flex justify-end items-center px-2'>
                                    <span className='px-2 font-semibold'>{item.votes_count}</span>
                                    {walletLogin !== null ?
                                        <button onClick={(e) => { e.stopPropagation(); voteProduct(item.slug) }} className='rounded-full p-1' style={{ background: '#EA3323' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                            </svg>
                                        </button>
                                        :
                                        <button onClick={() => {
                                            setIsToast(true);
                                            setToastMessage('Connect your wallet first.');
                                        }} className='rounded-full p-1' style={{ background: 'black' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" fill="white"></path>
                                            </svg>
                                        </button>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* ================== Edit Profile ================== */}
                {mobileQuery ?
                    < Dialog
                        fullScreen
                        open={openEditProfile}
                    >
                        <DialogContent>
                            <DialogContentText>
                                <div className='relative'>
                                    <button onClick={() => setOpenEditProfile(false)} className="block lg:hidden text-end close-modal-mobile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                    <h2 className='font-bold text-black mb-4 text-xl'>Edit Profile</h2>
                                    <div className="relative mb-20">
                                        {dataProfile?.img_banner === null ?
                                            <div className="banner-profile m-none rounded-t-lg bg-gray-400" alt="" ></div>
                                            :
                                            <img className="banner-profile m-none rounded-t-lg" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.img_banner} alt="" />
                                        }
                                        {walletLogin !== null &&
                                            <label for="dropzone-file-banner" className='absolute z-50 bg-gray-100/[.2] rounded-2xl edit-banner-profile'>
                                                <svg className='p-1 text-white' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                                    <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                                </svg>
                                                <input onChange={async (e) => {
                                                    if (e.target.files[0]) {
                                                        const form_data = new FormData();
                                                        form_data.append('img_banner', e.target.files[0]);
                                                        await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                                            headers: {
                                                                'Content-Type': 'multipart/form-data'
                                                            }
                                                        }).catch(function (error) {
                                                            console.log(error);
                                                        });
                                                        getDataUser();
                                                    }
                                                }} id="dropzone-file-banner" type="file" className="hidden" />
                                            </label>
                                        }
                                        <div className="absolute w-full px-4 max-w-xs ava-profile">
                                            <div className="relative drop-shadow items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                                {dataProfile?.photo === null ?
                                                    <img className="object-cover bg-gray-300 rounded-full w-36 h-36" src={userIcon} alt="" />
                                                    :
                                                    <img className="object-cover bg-gray-300 rounded-full w-36 h-36" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.photo} alt="" />
                                                }
                                                {walletLogin !== null &&
                                                    <label for="dropzone-file" className='absolute z-50 bg-gray-100/[.75] rounded-2xl edit-ava-profile'>
                                                        <svg className='p-1 text-gray-600' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                                            <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                                        </svg>
                                                        <input onChange={async (e) => {
                                                            if (e.target.files[0]) {
                                                                const form_data = new FormData();
                                                                form_data.append('photo', e.target.files[0]);
                                                                await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                                                    headers: {
                                                                        'Content-Type': 'multipart/form-data'
                                                                    }
                                                                }).catch(function (error) {
                                                                    console.log(error);
                                                                });
                                                                getDataUser();
                                                            }
                                                        }} id="dropzone-file" type="file" className="hidden" />
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="mb-6 mt-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Your Name</label>
                                        <input value={nameUser} onChange={(e) => { setNameUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">About Me</label>
                                        <input value={aboutUser} onChange={(e) => { setAboutUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Bio</label>
                                        <textarea onChange={(e) => { setBioUser(e.target.value) }} id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-core-900 focus:border-core-900" placeholder=""></textarea>
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Twitter</label>
                                        <input value={twitterUser} onChange={(e) => { setTwitterUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">LinkedIn</label>
                                        <input value={linkedInUser} onChange={(e) => { setLinkedInUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Personal Website</label>
                                        <input value={websiteUser} onChange={(e) => { setWebsiteUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                    </div>
                                    <div className='mb-6'>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">Interest</label>
                                        <div className='flex'>
                                            <div className='w-full grid grid-cols-2'>
                                                {dataCategory.map(function (item, i) {
                                                    return (
                                                        <div key={i} className="flex items-center mb-4">
                                                            <input
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setInterestUser([
                                                                            ...interestUser,
                                                                            item._id
                                                                        ]);
                                                                    } else {
                                                                        setInterestUser(
                                                                            interestUser.filter((x) => x.indexOf(item._id) === -1),
                                                                        );
                                                                    }
                                                                }}
                                                                id="checkbox-2" type="checkbox" value={dataCategory} defaultChecked={interestUser.includes(item._id)} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                            <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='md:space-x-2'>
                                        {isWait ?
                                            <div role="status">
                                                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-core-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            :
                                            <>
                                                <button onClick={() => { editDataUser() }} type="button" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
                                                <button onClick={() => {
                                                    setOpenEditProfile(false)
                                                    clearAllForm();
                                                }}
                                                    type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent >
                    </Dialog >
                    :
                    < Dialog
                        fullWidth={true}
                        maxWidth='sm'
                        open={openEditProfile}
                    >
                        <DialogContent>
                            <DialogContentText>
                                <h2 className='font-bold text-black mb-4 text-xl'>Edit Profile</h2>
                                <div className="relative mb-20">
                                    {dataProfile?.img_banner === null ?
                                        <div className="banner-profile m-none rounded-t-lg bg-gray-400" alt="" ></div>
                                        :
                                        <img className="banner-profile m-none rounded-t-lg" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.img_banner} alt="" />
                                    }
                                    {walletLogin !== null &&
                                        <label for="dropzone-file-banner" className='absolute z-50 bg-gray-100/[.2] rounded-2xl edit-banner-profile'>
                                            <svg className='p-1 text-white' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                                <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                            </svg>
                                            <input onChange={async (e) => {
                                                if (e.target.files[0]) {
                                                    const form_data = new FormData();
                                                    form_data.append('img_banner', e.target.files[0]);
                                                    await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                                        headers: {
                                                            'Content-Type': 'multipart/form-data'
                                                        }
                                                    }).catch(function (error) {
                                                        console.log(error);
                                                    });
                                                    getDataUser();
                                                }
                                            }} id="dropzone-file-banner" type="file" className="hidden" />
                                        </label>
                                    }
                                    <div className="absolute w-full px-4 max-w-xs ava-profile">
                                        <div className="relative drop-shadow items-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                            {dataProfile?.photo === null ?
                                                <img className="object-cover bg-gray-300 rounded-full w-36 h-36" src={userIcon} alt="" />
                                                :
                                                <img className="object-cover bg-gray-300 rounded-full w-36 h-36" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.photo} alt="" />
                                            }
                                            {walletLogin !== null &&
                                                <label for="dropzone-file" className='absolute z-50 bg-gray-100/[.75] rounded-2xl edit-ava-profile'>
                                                    <svg className='p-1 text-gray-600' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                                        <path fill="currentColor" fill-rule="evenodd" d="M15.586 3a2 2 0 0 1 2.828 0L21 5.586a2 2 0 0 1 0 2.828L19.414 10 14 4.586 15.586 3zm-3 3-9 9A2 2 0 0 0 3 16.414V19a2 2 0 0 0 2 2h2.586A2 2 0 0 0 9 20.414l9-9L12.586 6z" clip-rule="evenodd" />
                                                    </svg>
                                                    <input onChange={async (e) => {
                                                        if (e.target.files[0]) {
                                                            const form_data = new FormData();
                                                            form_data.append('photo', e.target.files[0]);
                                                            await axios.put(`/user/${walletLogin}/upload`, form_data, {
                                                                headers: {
                                                                    'Content-Type': 'multipart/form-data'
                                                                }
                                                            }).catch(function (error) {
                                                                console.log(error);
                                                            });
                                                            getDataUser();
                                                        }
                                                    }} id="dropzone-file" type="file" className="hidden" />
                                                </label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="mb-6 mt-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Your Name</label>
                                    <input value={nameUser} onChange={(e) => { setNameUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">About Me</label>
                                    <input value={aboutUser} onChange={(e) => { setAboutUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Bio</label>
                                    <textarea placeholder='New bio' onChange={(e) => { setBioUser(e.target.value) }} id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-core-900 focus:border-core-900"></textarea>
                                </div>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Twitter</label>
                                    <input value={twitterUser} onChange={(e) => { setTwitterUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">LinkedIn</label>
                                    <input value={linkedInUser} onChange={(e) => { setLinkedInUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                </div>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Personal Website</label>
                                    <input value={websiteUser} onChange={(e) => { setWebsiteUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                                </div>
                                <div className='mb-6'>
                                    <label className="block mb-2 text-sm font-medium text-gray-900">Interest</label>
                                    <div className='flex'>
                                        <div className='w-full grid grid-cols-2'>
                                            {dataCategory.map(function (item, i) {
                                                return (
                                                    <div key={i} className="flex items-center mb-4">
                                                        <input
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setInterestUser([
                                                                        ...interestUser,
                                                                        item._id
                                                                    ]);
                                                                } else {
                                                                    setInterestUser(
                                                                        interestUser.filter((x) => x.indexOf(item._id) === -1),
                                                                    );
                                                                }
                                                            }}
                                                            id="checkbox-2" type="checkbox" value={dataCategory} defaultChecked={interestUser.includes(item._id)} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                        <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='md:space-x-2'>
                                    {isWait ?
                                        <div role="status">
                                            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-core-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            <button onClick={() => { editDataUser() }} type="button" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
                                            <button onClick={() => {
                                                setOpenEditProfile(false)
                                                clearAllForm();
                                            }}
                                                type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                                        </>
                                    }
                                </div>
                            </DialogContentText>
                        </DialogContent >
                    </Dialog >
                }

                {
                    isToast &&
                    <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                        <div className="text-sm font-normal flex items-center">
                            <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                            </svg>
                            <span>{toastMessage}</span>
                        </div>
                    </div>
                }
            </div>
        );
}

export default ProfileDetail;

import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home/Home';
import Stories from './components/Stories/Stories';
import StoriesDetail from './components/Stories/StoriesDetail';
import About from './components/About/About';
import Profile from './components/Profile/Profile';
import Search from './components/Search';
// import WalletConnectPage from './components/WalletConnect';
import RecapInvest from './components/RecapTable/RecapInvest';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />}  />
        <Route path='/stories' element={<Stories />}  />
        <Route path='/stories/:slug' element={<StoriesDetail />}  />
        <Route path='/recapinvest' element={<RecapInvest />}  />
        <Route path='/about-us' element={<About />}  />
        <Route path='/profile' element={<Profile />}  />
        <Route path='/search' element={<Search />}  />
        <Route path='/search/:product' element={<Search />}  />
      </Routes>
    </Router>
  );
}

export default App;

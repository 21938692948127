
import lsLogo from '../assets/logo.png';
import lsLogoCircle from '../assets/circle-logo.png';
import userIcon from '../assets/icon/user.png';
import { FaPlus } from 'react-icons/fa';
import {
    Link
} from "react-router-dom";
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ethers } from "ethers";
import axios from '../utils/axiosInt';
import axiosDefault from 'axios';
import * as EmailValidator from 'email-validator';
import Trumbowyg from 'react-trumbowyg';
import 'react-trumbowyg/dist/trumbowyg.min.css';
import { setGlobalState, useGlobalState } from '../utils/globalState';

import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { GoTrueClient } from '@supabase/supabase-js';

function Header() {
    var slugify = require('slugify')
    const [openLogin, setOpenLogin] = useState(false);
    const [isWait, setIsWait] = useState(false);
    const [navShow, setNavShow] = useState(false);
    const [isToast, setIsToast] = useState(false);
    const [open, setOpen] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openSubscribe, setOpenSubscribe] = useState(false);
    const [openWelcome, setOpenWelcome] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [searchProduct, setSearchProduct] = useState('');
    // const [userBalance, setUserBalance] = useState(null);
    // const [dataProfile, setDataProfile] = useState([]);
    const [dataProfile] = useGlobalState("dataProfile");
    const [walletLogin] = useGlobalState("walletAddress");
    const [dataCategory, setDataCategory] = useState([]);
    const [dataCategoryCheck, setDataCategoryCheck] = useState([]);
    // const [provider, setProvider] = useState(null);
    const [nameUser, setNameUser] = useState('');
    const [emailUser, setEmailUser] = useState('');

    const [previewProduct, setPreviewProduct] = useState([]);
    const [previewProductURLs, setPreviewProductURLs] = useState([]);
    const [logoProduct, setLogoProduct] = useState(null);
    const [nameProduct, setNameProduct] = useState('');
    const [descriptionProduct, setDescriptionProduct] = useState('');
    const [summaryProduct, setSummaryProduct] = useState('');
    const [websiteProduct, setWebsiteProduct] = useState('');
    const [emailProduct, setEmailProduct] = useState('');
    const [twitterProduct, setTwitterProduct] = useState('');
    const [youtubeProduct, setYoutubeProduct] = useState('');
    const [categoryProduct, setCategoryProduct] = useState([]);

    const clearAllForm = () => {
        setNameProduct('');
        setSummaryProduct('');
        setDescriptionProduct('');
        setWebsiteProduct('');
        setEmailProduct('');
        setTwitterProduct('');
        setYoutubeProduct('');
        setCategoryProduct([]);
        setPreviewProduct([]);
        setPreviewProductURLs([]);
        setLogoProduct(null);
    };

    const addDataProduct = async () => {
        setIsWait(true);
        if (nameProduct === '') {
            setIsToast(true);
            setToastMessage('Please insert all required details.');
            setIsWait(false);
            return;
        }

        let slugProd = slugify(nameProduct, {
            lower: true
        });
        await axios.get(`/product/${slugProd}`, { withCredentials: true }).then((resp) => {
            setIsToast(true);
            setToastMessage('Seems this product is already exist!');
            setIsWait(false);
            return;
        }).catch(async (err) => {
            var value = summaryProduct;
            var summaryLength = value.length;
            var value2 = descriptionProduct;
            var descLength = value2.length;

            if (summaryProduct === '' || descriptionProduct === '' || websiteProduct === '' || categoryProduct.length === 0 || twitterProduct === '') {
                setIsToast(true);
                setToastMessage('Please insert all required details.');
                setIsWait(false);
                return;
            }

            if (summaryLength <= 15 || descLength <= 15) {
                setIsToast(true);
                setToastMessage('Please insert Tagline or Description form properly.');
                setIsWait(false);
                return;
            }

            // category array object to array string
            var ctgryData = categoryProduct.map(function (item) {
                return item['_id'];
            });

            let ytUrl;
            if (youtubeProduct === '') {
                ytUrl = null;
            } else {
                ytUrl = youtubeProduct;
            }

            let data = {
                "name": nameProduct,
                "tagline": summaryProduct,
                "description": descriptionProduct,
                "website": websiteProduct,
                "email": emailProduct,
                "twitter_url": twitterProduct,
                "youtube_url": ytUrl,
                "categories": ctgryData,
            }

            await axios.post('/product', data).catch(function (error) {
                console.log(error);
                setIsToast(true);
                setToastMessage('Something wrong, please try again later.');
                setOpen(false);
                setIsWait(false);
            }).then(async () => {
                let slugformat = slugify(nameProduct, {
                    lower: true
                });

                const form_data = new FormData();
                if (logoProduct !== null) {
                    form_data.append('logo', logoProduct);
                }
                if (previewProduct.length !== 0) {
                    for (let i = 0; i < previewProduct.length; i++) {
                        form_data.append('banners', previewProduct[i]);
                    }
                }
                await axios.put(`/product/${slugformat}/upload`, form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).catch(function (error) {
                    console.log(error);
                    setIsToast(true);
                    setToastMessage('Something wrong, please try again later.');
                    setOpen(false);
                    setIsWait(false);
                });
                setOpen(false);
                setIsToast(true);
                setToastMessage('Successfully Added!');
            });
            clearAllForm();
            setIsWait(false);
        });
    }

    const regSubs = async (email) => {
        try {
            let data = {
                "email": email,
                "reactivate_existing": false,
                "send_welcome_email": true,
                "utm_medium": "organic",
                "referring_site": "launchy.app"
            }

            await axios.post(`/user/subscribe`, data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(() => {
                setIsToast(true)
                setToastMessage('Email successfully subscribed!')
            }).catch(function (error) {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const addDataUser = async (status, email) => {
        let statusSubs;

        if (status === "subs") {
            statusSubs = true;
            regSubs(email);
        } else if (status === "skip") {
            statusSubs = false;
        }

        // get data location
        const loc = await axiosDefault.get('https://ipapi.co/json/');

        // category array object to array string
        var ctgryData = dataCategoryCheck.map(function (item) {
            return item['_id'];
        });

        let data = {
            "wallet_address": walletLogin,
            "name": nameUser,
            "is_subscribed": statusSubs,
            "email": email,
            "location": loc.data.country_name,
            "interests": ctgryData
        }

        await axios.post('/user', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getDataUser = async (walletAdd) => {
        let address = walletAdd;
        let data = {
            "wallet_address": walletAdd
        }
        await axios.post('/auth/login', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(function (error) {
            if (error.response.data.code_status === 404) {
                setOpenProfile(true);
            }
        }).then(async () => {
            const response = await axios.get(`/user/${address}`).catch(function (error) {
                if (error.response.data.code_status === 404) {
                    setOpenProfile(true);
                }
            });
            if (response !== undefined) {
                setGlobalState("dataProfile", response.data.data);
                setGlobalState("isActiveProfile", true);
            }
        });
    }

    const getDataCategory = async () => {
        const response = await axios.get(`/category`).catch(function (error) {
            console.log(error);
        });
        setDataCategory(response.data.data);
    }

    const changeWallet = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("wallet_requestPermissions", [{
            eth_accounts: {}
        }]).then(async () => {
            await provider.send("eth_requestAccounts", []);
        }).then(async () => {
            const signer = provider.getSigner();
            const walletAdd = await signer.getAddress()
            setGlobalState("walletAddress", walletAdd);
            localStorage.setItem('LSwalletLogin', walletAdd);
            setOpenLogin(false);
        });
    }

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            if (window.ethereum.selectedAddress === null) {
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const walletAdd = await signer.getAddress()
                setGlobalState("walletAddress", walletAdd);
                localStorage.setItem('LSwalletLogin', walletAdd);
                setOpenLogin(false);
                getDataUser(walletAdd);
            } else {
                changeWallet();
            }
        } else if (!window.ethereum) {
            setIsToast(true);
            setToastMessage('Please install MetaMask browser extension to interact');
        }
    };

    var handleConnect = async () => {
        setOpenLogin(false);
        try {
            var provider = new WalletConnectProvider({
                rpc: {
                    1: "https://cloudflare-eth.com/",
                    137: "https://polygon-rpc.com/",
                },
            });
            await provider.enable();

            const web3 = new Web3(provider);
            window.w3 = web3

            var accounts = await web3.eth.getAccounts();
            setGlobalState("walletAddress", accounts[0]);
            localStorage.setItem('LSwalletLogin', accounts[0]);
        } catch (error) {
            console.log(error);
        }
    }

    var handleDisconnect = async () => {
        try {
            var provider = new WalletConnectProvider({
                rpc: {
                    1: "https://cloudflare-eth.com/",
                    137: "https://polygon-rpc.com/",
                },
            });
            await provider.disconnect()
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        var retrievedWallet = localStorage.getItem('LSwalletLogin');
        if (retrievedWallet !== null) {
            setGlobalState("walletAddress", retrievedWallet);
            getDataUser(retrievedWallet);
        } else {
            if (walletLogin !== null) {
                getDataUser(walletLogin);
            } else {
                setGlobalState("isSubsDefault", true);
            }
        }
    }, [walletLogin]);

    useEffect(() => {
        getDataCategory();
    }, [dataCategoryCheck]);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    useEffect(() => {
        if (previewProductURLs.length < 1) return;
        for (let i = 0; i < previewProductURLs.length; i++) {
            setPreviewProduct(images => ([
                ...images,
                previewProductURLs[i]
            ]))
        }
    }, [previewProductURLs]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <header className="py-4 bg-white sm:py-5  border-b border-gray-200">
                <div className="px-4 lg:px-0 mx-auto max-w-7xl">
                    <nav className="flex items-center justify-between">
                        <div className='flex'>
                            <div className="flex pr-4 lg:hidden">
                                <button type="button" className="text-gray-900" onClick={() => {
                                    if (navShow) {
                                        setNavShow(false);
                                    } else {
                                        setNavShow(true);
                                    }
                                }}>
                                    {!navShow ?
                                        <span aria-hidden={true} className="transition duration-300">
                                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                                            </svg>
                                        </span>
                                        :
                                        <span aria-hidden={true} className="transition duration-300">
                                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </span>
                                    }
                                </button>
                            </div>
                            <div className="flex shrink-0 pr-4">
                                <a href="/#" className="flex">
                                    <img className="hidden md:block w-auto h-8" src={lsLogo} alt=""></img>
                                    <img className="md:hidden w-auto h-10" src={lsLogoCircle} alt=""></img>
                                </a>
                            </div>
                        </div>

                        <form className="flex items-center">
                            {/* <td dangerouslySetInnerHTML={{__html: html}} /> */}
                            <div className="relative w-full">
                                <input type="text" id="voice-search" onChange={(e) => { setSearchProduct(e.target.value) }} className="border border-gray-300 text-black text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full pr-10 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-core-900 dark:focus:border-core-900" placeholder="Search for products" />
                                <Link to={`/search/${searchProduct}`}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3">
                                    <svg aria-hidden={true} className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>
                            <div className="flex pl-4 lg:hidden">
                                {dataProfile.length === 0 ?
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        setIsToast(true);
                                        setToastMessage('Connect your wallet first.');
                                    }} style={{ width: '5vh' }} className="drop-shadow flex items-center max-w-xs ml-4 rounded-full">
                                        <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={userIcon} alt="" />
                                    </button>
                                    : dataProfile?.photo === null ?
                                        <Link to="/profile" style={{ width: '5vh' }} className="drop-shadow flex items-center max-w-xs ml-4 rounded-full">
                                            <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={userIcon} alt="" />
                                        </Link>
                                        :
                                        <Link to="/profile" style={{ width: '5vh' }} className="drop-shadow flex items-center max-w-xs ml-4 rounded-full">
                                            <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.photo} alt="" />
                                        </Link>
                                }
                            </div>
                        </form>

                        {/* =========================== Desktop Navbar =========================== */}
                        <div className="hidden lg:items-center lg:justify-start ml-5 lg:mr-auto lg:space-x-5 lg:flex">
                            <Link to="/"
                                className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                Home
                            </Link>

                            <a href="/#"
                                className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                Tools
                            </a>

                            <Link to="/stories"
                                className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                Stories
                            </Link>

                            <Link to="/about-us"
                                className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                About Us
                            </Link>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:justify-end">
                            {(dataProfile.length !== 0 && walletLogin !== null) &&
                                <button onClick={() => { setOpen(true) }} className="inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                    <FaPlus className='mr-2' /> Submit Yours
                                </button>
                            }

                            {walletLogin !== null ?
                                <button onClick={() => {
                                    handleDisconnect();
                                    setGlobalState("walletAddress", null);
                                    setGlobalState("dataProfile", []);
                                    localStorage.removeItem('LSwalletLogin')
                                    setOpenLogin(true)
                                }
                                } className="justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                    Disconnect
                                </button> :
                                <button onClick={() => setOpenLogin(true)} className="justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                    Connect Wallet
                                </button>
                            }

                            {walletLogin !== null ?
                                <div className='text-center mt-2 truncate-wallet'>
                                    <span className='text-gray-400'>{walletLogin}</span>
                                </div>
                                :
                                ''
                            }

                            {walletLogin === null ?
                                <button onClick={() => {
                                    setIsToast(true);
                                    setToastMessage('Connect your wallet first.');
                                }} className="drop-shadow flex items-center max-w-xs ml-4 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                    <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={userIcon} alt="" />
                                </button>
                                :
                                dataProfile.length === 0 ?
                                    <button onClick={() => {
                                        setIsToast(true);
                                        setToastMessage('Connect your wallet first.');
                                    }} className="drop-shadow flex items-center max-w-xs ml-4 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                        <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={userIcon} alt="" />
                                    </button>
                                    : dataProfile?.photo === null ?
                                        <Link to="/profile" className="drop-shadow flex items-center max-w-xs ml-4 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                            <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={userIcon} alt="" />
                                        </Link>
                                        :
                                        <Link to="/profile" className="drop-shadow flex items-center max-w-xs ml-4 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-core-900">
                                            <img className="object-cover bg-gray-300 rounded-full w-9 h-9" src={'https://api-launchy.rshme.codes/storage/' + dataProfile?.photo} alt="" />
                                        </Link>
                            }
                        </div>
                        {/* =========================== Desktop Navbar End =========================== */}
                    </nav>

                    {/* =========================== Mobile & Tab Navbar =========================== */}
                    {navShow &&
                        <nav className='transition-all duration-300'>
                            <div className="px-1 pt-8 pb-4">
                                <div className="grid gap-y-6">

                                    <Link to="/"
                                        className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                        Home
                                    </Link>

                                    <a href="/#"
                                        className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                        Tools
                                    </a>

                                    <Link to="/stories"
                                        className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                        Stories
                                    </Link>

                                    <Link to="/about-us"
                                        className="text-base font-medium text-gray-900 transition-all duration-200 hover:text-core-900">
                                        About Us
                                    </Link>
                                </div>

                                <hr className="my-6 border-gray-200"></hr>

                                <div className="flex">

                                    {(dataProfile.length !== 0 && walletLogin !== null) &&
                                        <button onClick={() => { setOpen(true) }} type="submit" className="w-full justify-center inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                            <FaPlus className='mr-2' /> Submit Yours
                                        </button>
                                    }

                                    {walletLogin !== null ?
                                        <button onClick={() => {
                                            handleDisconnect();
                                            setGlobalState("walletAddress", null);
                                            setGlobalState("dataProfile", []);
                                            localStorage.removeItem('LSwalletLogin')
                                            setOpenLogin(true);
                                        }
                                        } className="justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                            Disconnect
                                        </button> :
                                        <button onClick={() => setOpenLogin(true)} className="justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-lg border border-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 dark:bg-core-900 dark:hover:bg-core-900 dark:focus:ring-core-900">
                                            Connect Wallet
                                        </button>
                                    }

                                </div>

                                {walletLogin !== null ?
                                    <div className='text-center mt-2'>
                                        <span className='text-gray-400'>{walletLogin}</span>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                        </nav>
                    }
                    {/* =========================== Mobile & Tab Navbar End =========================== */}
                </div>

                {
                    isToast &&
                    <div id="toast-bottom-right" style={{ zIndex: '1500' }} className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                        <div className="text-sm font-normal flex items-center">
                            <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                            </svg>
                            <span>{toastMessage}</span>
                        </div>
                    </div>
                }
            </header >

            {/* ================== Submit Modal ================== */}
            < Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
            >
                <DialogContent>
                    <DialogContentText>
                        <h2 className='font-bold text-black mb-4'>Submit Yours</h2>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input value={nameProduct} onChange={(e) => { setNameProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Profile Image</label>
                            <div class="mb-4">
                                {logoProduct !== null ?
                                    <div>
                                        <img src={URL.createObjectURL(logoProduct)} style={{ height: '15vh' }} alt="" />
                                        <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setLogoProduct(null) }}>Remove</button>
                                    </div>
                                    :
                                    <div class="flex items-center justify-center w-full lg:w-6/12">
                                        <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                            <div class="flex flex-col items-center justify-center pt-7">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                                <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                    Select Profile Image</p>
                                            </div>
                                            <input type="file" onChange={(event) => { setLogoProduct(event.target.files[0]) }} class="opacity-0" />
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Gallery</label>
                            <div class="mb-4">
                                {previewProductURLs.length !== 0 ?
                                    <div>
                                        <div className="grid grid-cols-3">
                                            {previewProduct.map(function (item, i) {
                                                return (
                                                    <img className="px-2 mt-2" src={URL.createObjectURL(item)} style={{ width: '100%' }} alt="" />
                                                )
                                            })}
                                            <div class="flex items-center justify-center w-full lg:w-6/12 mt-2">
                                                <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                                    <div class="flex flex-col items-center justify-center pt-7">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                            fill="currentColor">
                                                            <path fill-rule="evenodd"
                                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                                clip-rule="evenodd" />
                                                        </svg>
                                                        <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Add more</p>
                                                    </div>
                                                    <input type="file" onChange={(event) => {
                                                        setPreviewProductURLs([...event.target.files])
                                                    }} class="opacity-0" accept="image/*" multiple />
                                                </label>
                                            </div>
                                        </div>
                                        <button className="mt-2 text-sm text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-900 font-medium rounded-lg w-full sm:w-auto px-4 py-1.5 text-center" onClick={() => { setPreviewProduct([]); setPreviewProductURLs([]); }}>Remove</button>
                                    </div>
                                    :
                                    <div class="flex items-center justify-center w-full lg:w-6/12">
                                        <label class="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                            <div class="flex flex-col items-center justify-center pt-7">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="w-12 h-12 text-gray-400 group-hover:text-gray-600" viewBox="0 0 20 20"
                                                    fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                                <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                    Select Gallery Image</p>
                                            </div>
                                            <input type="file" onChange={(event) => {
                                                setPreviewProductURLs([...event.target.files])
                                            }} class="opacity-0" accept="image/*" multiple />
                                        </label>
                                    </div>
                                }
                            </div>
                            {/* <input onChange={(event) => {
                                setPreviewProduct(event.target.files);
                            }}
                                className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none" accept="image/*" id="user_avatar" type="file" multiple></input> */}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Tagline</label>
                            <input value={summaryProduct} onChange={(e) => { setSummaryProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                            <Trumbowyg onPaste={(e) => { setDescriptionProduct(e.target.innerHTML) }} onChange={(e) => { setDescriptionProduct(e.target.innerHTML) }} placeholder="paste here..." id='react-trumbowyg' />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Website</label>
                            <input value={websiteProduct} onChange={(e) => { setWebsiteProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                            <input value={emailProduct} onChange={(e) => { setEmailProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="youremail@gmail.com" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Twitter URL</label>
                            <input value={twitterProduct} onChange={(e) => { setTwitterProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Youtube URL(Optional)</label>
                            <input value={youtubeProduct} onChange={(e) => { setYoutubeProduct(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="https://" />
                            <label className="block mb-2 text-xs text-gray-500 mt-2">Example URL: https://www.youtube.com/watch?v=oQQ4PKaSxFQ</label>
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Category</label>
                            <div className='flex'>
                                <div className='w-full grid grid-cols-2'>
                                    {dataCategory.map(function (item, i) {
                                        return (
                                            <div key={i} className="flex items-center mb-4">
                                                <input
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCategoryProduct([
                                                                ...categoryProduct,
                                                                {
                                                                    _id: item._id
                                                                },
                                                            ]);
                                                        } else {
                                                            setCategoryProduct(
                                                                categoryProduct.filter((x) => x._id !== item._id),
                                                            );
                                                        }
                                                    }}
                                                    id="checkbox-2" type="checkbox" value={dataCategory} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='md:space-x-2'>
                            {isWait ?
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-core-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button onClick={() => { addDataProduct() }} type="button" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
                                    <button onClick={() => { setOpen(false); clearAllForm(); }} type="button" className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Close</button>
                                </>
                            }
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {/* ================== Profile Modal ================== */}
            < Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openProfile}
            >
                <DialogContent>
                    <DialogContentText>
                        <h2 className='font-bold text-black mb-4 text-xl'>Add Your Profile</h2>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Your Name</label>
                            <input value={nameUser} onChange={(e) => { setNameUser(e.target.value) }} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="" />
                        </div>
                        <div className='mb-6'>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Interest</label>
                            <div className='flex'>
                                <div className='w-full grid grid-cols-2'>
                                    {dataCategory.map(function (item, i) {
                                        return (
                                            <div key={i} className="flex items-center mb-4">
                                                <input
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setDataCategoryCheck([
                                                                ...dataCategoryCheck,
                                                                {
                                                                    _id: item._id
                                                                },
                                                            ]);
                                                        } else {
                                                            setDataCategoryCheck(
                                                                dataCategoryCheck.filter((x) => x._id !== item._id),
                                                            );
                                                        }
                                                    }}
                                                    id="checkbox-2" type="checkbox" value={dataCategory} className="w-4 h-4 text-core-900 bg-gray-100 rounded border-gray-300 focus:ring-core-900" />
                                                <label htmlFor="checkbox-2" className="ml-2 text-sm font-medium text-gray-900">{item.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className='md:space-x-2'>
                            <button onClick={() => {
                                if (nameUser !== '') {
                                    setOpenProfile(false)
                                    setOpenSubscribe(true)
                                } else {
                                    setIsToast(true);
                                    setToastMessage('Please insert your name form!');
                                }
                            }}
                                type="submit" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {/* ================== Subscribe Modal ================== */}
            < Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openSubscribe}
            >
                <DialogContent>
                    <DialogContentText>
                        <h2 className='font-bold text-black mb-4 text-xl'>Subscribe</h2>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input value={emailUser} onChange={(e) => { setEmailUser(e.target.value) }} type="text" className="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-core-900 focus:border-core-900 block w-full p-2.5" placeholder="username@gmail.com" />
                            <span className='text-black'>Keep up to date with the latest web3 products, strategies, and trends. We will not display your email in your profile, and you can unsubscribe anytime. </span>
                        </div>

                        <div className='md:space-x-2'>
                            <button onClick={() => {
                                addDataUser('skip', null).then(() => {
                                    setOpenSubscribe(false);
                                    setOpenWelcome(true);
                                });
                            }} type="submit" className="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Skip</button>
                            <button onClick={() => {
                                let mailChecker = EmailValidator.validate(emailUser);
                                if (mailChecker) {
                                    addDataUser('subs', emailUser).then(() => {
                                        setOpenSubscribe(false);
                                        setOpenWelcome(true);
                                    });
                                } else {
                                    setIsToast(true);
                                    setToastMessage('Please enter your email correctly.');
                                }
                            }} type="submit" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {/* ================== Welcome Modal ================== */}
            < Dialog
                fullWidth={true}
                maxWidth='sm'
                open={openWelcome}
            >
                <DialogContent>
                    <DialogContentText>
                        <h2 className='font-bold text-black mb-4 text-2xl'>Welcome!</h2>
                        <div className="mb-6">
                            <p className='text-black text-xl mb-4'>Hi There!</p>
                            <span className='text-black text-base'>The Discover-To-Earn awards platform. Upvote for the best new projects and get rewarded for it.</span>
                        </div>

                        <div className='md:space-x-2'>
                            <button onClick={() => {
                                getDataUser(walletLogin);
                                setOpenWelcome(false);
                            }} type="submit" className="text-white bg-core-900 hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Ok</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >

            {/* ================== Login Modal ================== */}
            < Dialog
                fullWidth={true}
                maxWidth='xs'
                open={openLogin}
            >
                <DialogContent>
                    <DialogContentText>
                        <h2 className='font-black text-black mb-4 text-2xl'>Connect Wallet</h2>
                        <div className="mb-4">
                            <button onClick={connectWalletHandler} className="w-full justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-2xl border hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900">
                                <div className='flex'>
                                    <img width="20" src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png' />
                                    <span className='mx-2'>Metamask</span>
                                </div>
                            </button>
                        </div>
                        <div className="mb-4">
                            <button onClick={handleConnect} className="w-full justify-center drop-shadow inline-flex items-center py-2.5 px-3 mr-2 text-sm font-medium text-white bg-core-900 rounded-2xl border hover:bg-core-900 focus:ring-4 focus:outline-none focus:ring-core-900">
                                <div className='flex'>
                                    <img width="20" src='https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png' />
                                    <span className='mx-2'>Wallet Connect</span>
                                </div>
                            </button>
                        </div>
                        <div className='text-center text-lg mb-6'>
                            <span>This wallet becomes your primary connected address. You can change this later though!</span>
                        </div>
                        <div className='md:space-x-2 text-center'>
                            <button onClick={() => {
                                setOpenLogin(false);
                            }} type="submit" className="focus:outline-none focus:ring-white font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Cancel</button>
                        </div>
                    </DialogContentText>
                </DialogContent >
            </Dialog >
        </div >
    );
}

export default Header;
import '../App.css';
import { useEffect } from 'react';
import { setGlobalState, useGlobalState } from '../utils/globalState';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

// import required modules
import { Pagination } from "swiper";
import { Navigation } from "swiper";

function DetailSlider() {
    const [isActiveSlider] = useGlobalState("isActiveSlider");
    const [dataSlider] = useGlobalState("dataSlider");
    const [indexSlider] = useGlobalState("indexSlider");

    if (isActiveSlider)
        return (
            <div className='relative w-screen f-screen'>
                <div className='ds-wrapper' style={{ zIndex: '2000' }}>
                    <button className='ds-close' onClick={() => {
                        setGlobalState("isActiveSlider", false);
                        setGlobalState("dataSlider", []);
                        setGlobalState("indexSlider", 0);
                    }}>
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" fill="white"></path>
                        </svg>
                    </button>
                    <div className='mx-auto xl:max-w-4xl 2xl:max-w-7xl ds-swiper h-full'>
                        <Swiper
                            initialSlide={indexSlider}
                            navigation={true}
                            modules={[Navigation, Pagination]}
                            pagination={{
                                clickable: true,
                            }}
                            className="mySwiper ds-swiper"
                        >
                            {/* {(item?.youtube_url !== null && item?.youtube_url) !== '' &&
                            <SwiperSlide>
                                <iframe title='url_youtube' className='rounded-lg youtube-frame' style={{ width: '100%' }}
                                    src={urlYoutube}>
                                </iframe>
                            </SwiperSlide>
                        }
                        {item?.banners?.map(function (item, i) {
                            return (
                                <SwiperSlide>
                                    <button onClick={() => { setGlobalState('isActiveSlider', true) }}>
                                        <img src={'https://api-launchy.rshme.codes/storage/' + item} alt="" />
                                    </button>
                                </SwiperSlide>
                            )
                        }
                        )} */}
                            {dataSlider?.map(function (item, i) {
                                return (
                                    <SwiperSlide className='ds-swiperslide'>
                                        <img className='mx-auto' src={'https://api-launchy.rshme.codes/storage/' + item} alt="" />
                                    </SwiperSlide>
                                )
                            }
                            )}
                        </Swiper>
                    </div>
                </div>
            </div>
        );
}

export default DetailSlider;
